import styled, { css } from 'styled-components';

export const Container = styled('div')`
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
`;

export const ContainerFileUpload = styled('div')`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) =>
        props.zIndex &&
        css`
            z-index: ${props.zIndex};
        `}
`;
