import React, { useRef, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Row } from '../../../../app/global-styles';
import { error, loading, success } from '../../../../components/alerts';
import Button from '../../../../components/button';
import confirmAlert from '../../../../components/confirm-alert';
import CheckBoxGroup from '../../../../components/form-components/checkbox-group';
import ControlledInput from '../../../../components/form-components/controlled-input';
import Icon from '../../../../components/icon';
import Popup from '../../../../components/popup';
import Table from '../../../../components/table';
import { colors } from '../../../../configs/theme';
import Request, { getLimit } from '../../../../utils/Request';
import { pathname2Query } from '../../../../utils/RouterRes';
import { parser } from '../../../../utils/Select';
import Votacao from '../votacao';

function Pautas({ open, setOpen }) {
    const tableRef = useRef();
    const [votacaoOpen, setVotacaoOpen] = useState(false);
    const [votacaoPayload, setVotacaoPayload] = useState({});
    const initialValues = {
        tipo_materia: {},
        materia: {},
    };

    const match = useRouteMatch();
    const location = useLocation();

    const url_params = pathname2Query(location.pathname, match.url);

    async function getMateriaTipoSelect(search) {
        const request = new Request();

        const req_materia_tipos = request.setRequest(
            'materia_tipos',
            'listar',
            { search }
        );
        const result = await request.execute();

        const { dados } = result[req_materia_tipos] || [];

        return parser('descricao', 'id', dados);
    }

    async function getMateriaSelect(search, tipo_id) {
        const request = new Request();

        const req_materia_situacoes = request.setRequest('materias', 'listar', {
            search,
            tipo_id,
            ignore_sessao_id: url_params.id,
        });
        const result = await request.execute();

        const { dados } = result[req_materia_situacoes] || [];

        return parser('nome_materia', 'id', dados);
    }

    async function removerPauta({ original } = {}) {
        if (original && original.materia_id) {
            const desativar = async () => {
                const request = new Request();

                const { materia_id, sessao_id } = original;

                const req_excluir = request.setRequest(
                    'sessao_pautas',
                    'excluir',
                    { materia_id, sessao_id }
                );
                const result = await request.execute();

                if (result[req_excluir] === true) {
                    success('Pauta excluída com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_excluir] === 'string') {
                    error(result[req_excluir]);
                } else {
                    error('Ocorreu um erro ao excluir anexo!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir pauta?',
                subtitle: `Ao confirmar a pauta ${original.descricao} será excluída!`,
                onConfirm() {
                    desativar(original).then();
                },
            });
        } else {
            error('Pauta inválida!');
        }
    }

    async function newVotacao({ materia_id, sessao_id }) {
        const request = new Request();

        const req_votacao = request.setRequest('votacoes', 'salvar', {
            votacao: {
                materia_id,
                sessao_id,
                unanime: 0,
            },
        });

        const result = await request.execute();

        if (
            result &&
            result[req_votacao] &&
            result[req_votacao] &&
            !isNaN(Number(result[req_votacao]))
        ) {
            return Number(result[req_votacao]);
        }

        error('Erro ao iniciar votação');
        return false;
    }

    async function handleChangeUnanime({
        votacao_id: id,
        materia_id,
        sessao_id,
        unanime,
    }) {
        const request = new Request();
        const loadToast = loading('Salvando unanimidade');

        const req_votacao = request.setRequest('votacoes', 'salvar', {
            votacao: {
                id,
                materia_id,
                sessao_id,
                unanime,
            },
        });

        const result = await request.execute();
        loadToast();

        if (
            result &&
            result[req_votacao] &&
            result[req_votacao] &&
            !isNaN(Number(result[req_votacao]))
        ) {
            success(`Unanimidade alterada com sucesso`);
            return Number(result[req_votacao]);
        }

        error('Erro ao iniciar votação');
        return false;
    }

    async function getMaterias({ page, limit: table_limit }) {
        const request = new Request();

        const limit = getLimit(page, table_limit);

        const req_pautas = request.setRequest('sessao_pautas', 'listar', {
            limit,
            sessao_id: url_params.id,
        });

        const result = await request.execute();

        if (result && result[req_pautas]) {
            return result[req_pautas];
        }
        return {};
    }

    async function handleSubmit(values, formState) {
        const pauta_to_save = {};
        const loadToast = loading('Salvando pauta');

        pauta_to_save.sessao = url_params.id;
        if (values.materia.value) {
            pauta_to_save.materia = values.materia.value;
        }

        try {
            const request = new Request();

            const req_pauta = request.setRequest('sessao_pautas', 'salvar', {
                sessao_id: pauta_to_save.sessao,
                materia_id: pauta_to_save.materia,
            });

            const result = await request.execute();

            if (result[req_pauta]) {
                loadToast();
                success(`Pauta adicionada com sucesso`);
                formState.setSubmitting(false);
                formState.resetForm();
                tableRef.current.fireFetchData();
            } else {
                loadToast();
                error('Não foi possível salvar pauta!');
                formState.setSubmitting(false);
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar pauta!');
            formState.setSubmitting(false);
        }
    }

    function makeForm(formState) {
        return (
            <Form>
                <Row height="auto" alignItems="center">
                    <Field
                        component={ControlledInput}
                        name="tipo_materia"
                        isClearable
                        type_select="async"
                        type="select"
                        label="Tipo de matéria"
                        placeholder="Tipo de matéria"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={getMateriaTipoSelect}
                    />
                    <Field
                        component={ControlledInput}
                        name="materia"
                        isClearable
                        type_select="async"
                        type="select"
                        label="Matéria"
                        placeholder="Matéria"
                        size={4}
                        required
                        defaultOptions
                        cacheOptions
                        loadOptions={(search) =>
                            getMateriaSelect(
                                search,
                                formState.values.tipo_materia.value
                            )
                        }
                    />
                    <Button
                        type="submit"
                        kind="save"
                        margin="0 15px"
                        height="36px"
                        disabled={formState.isSubmitting}
                    >
                        Adicionar
                    </Button>
                </Row>
            </Form>
        );
    }

    return (
        <>
            <Popup
                height="600px"
                title="Pautas"
                open={open}
                onClose={() => setOpen(false)}
                contentOverflow
            >
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={Yup.object().shape({
                        materia: Yup.mixed().validSelect(
                            'Matéria é obrigatório!'
                        ),
                    })}
                >
                    {makeForm}
                </Formik>
                <Table
                    headers={[
                        {
                            accessor: 'descricao',
                            name: 'Matéria',
                        },
                        {
                            accessor: '',
                            name: 'Votação',
                            Cell: (cellProps) => {
                                const { original } = cellProps;
                                const { unanime } = original;

                                if (
                                    unanime &&
                                    !isNaN(Number(unanime)) &&
                                    Number(unanime) === 1
                                ) {
                                    return <></>;
                                }

                                return (
                                    <Button
                                        onClick={() => {
                                            async function loadVotacao() {
                                                const votacaoToEdit = {
                                                    ...original,
                                                };
                                                if (!votacaoToEdit.votacao_id) {
                                                    const loadToast = loading(
                                                        'Iniciando votação'
                                                    );
                                                    const votacao_id = await newVotacao(
                                                        {
                                                            materia_id:
                                                                votacaoToEdit.materia_id,
                                                            sessao_id:
                                                                votacaoToEdit.sessao_id,
                                                        }
                                                    );
                                                    loadToast();
                                                    success(
                                                        'Votação iniciada com sucesso!'
                                                    );
                                                    votacaoToEdit.votacao_id = votacao_id;
                                                }

                                                setVotacaoPayload(
                                                    votacaoToEdit
                                                );
                                                setVotacaoOpen(true);
                                            }

                                            loadVotacao();
                                        }}
                                    >
                                        Votação
                                    </Button>
                                );
                            },
                        },
                        {
                            accessor: 'unanime',
                            name: 'Unânime',
                            Cell: (cellProps) => {
                                const { original } = cellProps;

                                const checkInteger = !isNaN(
                                    Number(original.unanime)
                                )
                                    ? Number(original.unanime)
                                    : null;

                                const checkValue =
                                    checkInteger === 1 ? [checkInteger] : [];

                                const {
                                    votacao_id,
                                    sessao_id,
                                    materia_id,
                                } = original;

                                return (
                                    <CheckBoxGroup
                                        buttonstyle
                                        inTable
                                        options={[
                                            { label: 'Unânime', value: 1 },
                                        ]}
                                        onChange={async (checkBoxValue) => {
                                            const [selected] = checkBoxValue;

                                            const updated = await handleChangeUnanime(
                                                {
                                                    unanime: selected ? 1 : 0,
                                                    votacao_id,
                                                    sessao_id,
                                                    materia_id,
                                                }
                                            );

                                            if (updated) {
                                                tableRef.current.fireFetchData();
                                            }
                                        }}
                                        value={checkValue}
                                    />
                                );
                            },
                        },
                    ]}
                    options={(table_props) => (
                        <Icon
                            hidden
                            size="16px"
                            hover={colors.red_error_message}
                            color={colors.black_table}
                            className="icon-trash"
                            onClick={() => removerPauta(table_props)}
                        />
                    )}
                    tableRef={tableRef}
                    data_function={getMaterias}
                />
            </Popup>
            <Votacao
                open={votacaoOpen}
                votacaoPayload={votacaoPayload}
                setVotacaoPayload={setVotacaoPayload}
                setOpen={setVotacaoOpen}
                onClose={() => {
                    tableRef.current.fireFetchData();
                }}
            />
        </>
    );
}

Pautas.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};

export default Pautas;
