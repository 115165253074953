import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { Field, Formik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import { Row } from '../../../app/global-styles';
import { error, loading, success } from '../../../components/alerts';
import Button from '../../../components/button';
import ControlledInput from '../../../components/form-components/controlled-input';
import PageContainer from '../../../components/page-container';
import Request from '../../../utils/Request';
import 'moment/locale/pt-br';
import { pathname2Query } from '../../../utils/RouterRes';

moment.locale('pt-br');

const formValues = {
    nome: '',
    partido: '',
};

function Vereadores() {
    const [vereador, setVereador] = useState({});
    const location = useLocation();
    const match = useRouteMatch();
    const url_params = pathname2Query(location.pathname, match.url);
    const history = useHistory();
    const initialValues =
        vereador && Object.keys(vereador).length > 0 ? vereador : formValues;

    async function getVereador(id) {
        const request = new Request();

        const req_vereador = request.setRequest('vereadores', 'listar', { id });

        const result = await request.execute();

        const { dados } = result[req_vereador];

        if (dados && dados.id) {
            const vereador_to_edit = {};

            vereador_to_edit.id = dados.id;
            vereador_to_edit.nome = dados.nome;
            vereador_to_edit.partido = dados.partido;

            setVereador(vereador_to_edit);
        } else {
            history.push('/painel/vereadores');
        }
    }

    async function handleSubmit(values, state) {
        const request = new Request();
        const vereador_to_save = {};

        if (vereador.id) {
            vereador_to_save.id = vereador.id;
        }

        vereador_to_save.nome = values.nome;
        vereador_to_save.partido = values.partido;

        const loadToast = loading('Salvando vereador');

        try {
            const req_vereador = request.setRequest('vereadores', 'salvar', {
                vereador: vereador_to_save,
            });

            const result = await request.execute();

            if (result[req_vereador] === true) {
                loadToast();
                success(`${vereador_to_save.nome} alterado com sucesso`);
                state.setSubmitting(false);
            } else if (Number(result[req_vereador]) > 0) {
                loadToast();
                success(`${vereador_to_save.nome} adicionado com sucesso`);
                history.push(
                    `/painel/vereadores/editor/id=${result[req_vereador]}`
                );
                state.setSubmitting(false);
            } else {
                loadToast();
                error('Não foi possível salvar vereador!');
                state.setSubmitting(false);
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar vereador!');
            state.setSubmitting(false);
        }
    }

    useEffect(() => {
        if (url_params.id && !isNaN(url_params.id)) {
            getVereador(url_params.id).then();
        } else {
            setVereador({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_params.id]);

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="nome"
                        placeholder="Nome"
                        label="Nome"
                        maxLength={255}
                        type="text"
                        required
                        size={6}
                    />
                    <Field
                        component={ControlledInput}
                        name="partido"
                        maxLength={255}
                        placeholder="Partido"
                        label="Sigla do Partido"
                        type="text"
                        required
                        size={2}
                        onChange={(event) => {
                            const { value } = event.target;
                            formState.setFieldValue(
                                'partido',
                                value.toUpperCase()
                            );
                        }}
                    />
                </Row>
                <Row padding="0 15px">
                    {Object.entries(vereador).length > 0 && (
                        <Button
                            type="reset"
                            kind="cancel"
                            margin="0 10px 0 0"
                            onClick={() => {
                                setVereador({});
                                formState.resetForm();
                            }}
                        >
                            Cancelar
                        </Button>
                    )}
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    function renderForm() {
        return (
            <PageContainer title="Vereador">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        nome: yup
                            .string()
                            .required('Nome é obrigatório!')
                            .max(255, '255 caracteres máximos'),
                        partido: yup
                            .string()
                            .required('Partido é obrigatório!')
                            .max(255, '10 caracteres máximos'),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        );
    }

    return (
        <PageContainer padding scroll>
            {renderForm()}
        </PageContainer>
    );
}

export default Vereadores;
