import React from 'react';

import PropTypes from 'prop-types';

import { colors } from '../../../configs/theme';
import ErrorMessage from '../../form-components/error-message';
import Icon from '../../icon';
import { ProgressBar, Container, Filename, Filesize } from './styles';

function ItemUploading({
    name,
    accepted,
    readableSize,
    error,
    progress,
    onCancel,
}) {
    return (
        <Container>
            <Filename accepted={accepted}>{name}</Filename>
            {readableSize && <Filesize>{readableSize}</Filesize>}
            <ProgressBar progress={progress} />
            {(!accepted || error) && (
                <ErrorMessage zIndex={5} error_message={error} />
            )}
        </Container>
    );
}

ItemUploading.propTypes = {
    name: PropTypes.string.isRequired,
    readableSize: PropTypes.string,
    error: PropTypes.string,
    progress: PropTypes.number,
    onCancel: PropTypes.func,
    accepted: PropTypes.bool,
};

export default ItemUploading;
