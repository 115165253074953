import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { colors } from '../../../configs/theme';
import Input from '../input';

function TableSearchInput({
    alias,
    searchParams,
    onClickFiltros,
    clearFunction,
    ...props
}) {
    const inputValue = useMemo(() => {
        const keysFiltering = Object.keys(searchParams).filter((key) => {
            const hasAlias = Object.keys(alias).includes(key);

            if (hasAlias) {
                const fieldValue = searchParams[key];

                if (Array.isArray(fieldValue) && fieldValue.length > 0) {
                    return true;
                }
                if (typeof fieldValue === 'string' && !!fieldValue) {
                    return true;
                }
                if (fieldValue === Object(fieldValue) && !!fieldValue.value) {
                    return true;
                }
            }

            return false;
        });

        const keysAliases = keysFiltering.map((key) => alias[key]);

        if (keysAliases.length > 0) {
            return `Filtrando por ${keysAliases.join(', ')}`;
        }

        return null;
        // eslint-disable-next react-hooks/exhaustive-deps
    }, [searchParams, alias]);

    if (inputValue) {
        props.value = inputValue;
        props.iconColor = colors.color_primary;
        props.iconFiltros = 'icon-close';
        props.iconFiltrosColor = colors.color_primary;
        props.onClickFiltros = () => clearFunction && clearFunction();
        props.borderColor = colors.color_primary;
        props.onClick = () => onClickFiltros && onClickFiltros();
        props.disabledByFiltros = true;
    }

    return (
        <Input
            icon="icon-lupa"
            kind="filtros"
            show_filtros
            onClickFiltros={onClickFiltros}
            {...props}
            width="266px"
        />
    );
}

export default TableSearchInput;

TableSearchInput.propTypes = {
    alias: PropTypes.objectOf(PropTypes.string).isRequired,
    searchParams: PropTypes.shape({
        values: PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.arrayOf(
                    PropTypes.shape({
                        value: PropTypes.string,
                        label: PropTypes.string,
                    })
                ),
                PropTypes.string,
                PropTypes.shape({
                    value: PropTypes.string,
                    label: PropTypes.string,
                }),
            ])
        ),
    }).isRequired,
    onClickFiltros: PropTypes.func.isRequired,
    clearFunction: PropTypes.func.isRequired,
};

TableSearchInput.defaultProps = {};
