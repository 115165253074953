import React from 'react';

import Icon from '../../../components/icon';
import { colors } from '../../../configs/theme';
import {
    Container,
    Card,
    ContainerIcon,
    Info,
    Title,
    Description,
} from './styles';

function Home() {
    return (
        <Container>
            <Card to="painel/sessoes">
                <ContainerIcon>
                    <Icon
                        className="icon-microfone"
                        size="45px"
                        color={colors.color_primary}
                    />
                </ContainerIcon>
                <Info>
                    <Title>Sessões</Title>
                    <Description>
                        Foro apropriado para a tomada de decisões sobre os
                        projetos de lei e outras matérias legislativas ou
                        administrativas, aprovadas ou rejeitadas em votação
                        pelos parlamentares. É dirigida pela Mesa Diretora de
                        acordo com o Regimento Interno da Casa. As decisões
                        votadas em Plenário são soberanas e prevalecem sobre
                        interesses ou vontades individuais.
                    </Description>
                </Info>
            </Card>
            <Card to="painel/comissoes">
                <ContainerIcon>
                    <Icon
                        className="icon-pessoas"
                        size="45px"
                        color={colors.color_primary}
                    />
                </ContainerIcon>
                <Info>
                    <Title>Comissões</Title>
                    <Description>
                        Órgãos da Casa Legislativa, de natureza técnica
                        especializada e que têm por objetivo prestar melhores
                        esclarecimentos aos parlamentares para a tomada de
                        decisões. Assim, as comissões elaboram estudos,
                        pareceres a respeito de determinados projetos de lei e
                        investigação de irregularidades sobre fato determinado.
                    </Description>
                </Info>
            </Card>
            <Card to="painel/materias">
                <ContainerIcon>
                    <Icon
                        className="icon-contratos"
                        size="45px"
                        color={colors.color_primary}
                    />
                </ContainerIcon>
                <Info>
                    <Title>Matérias</Title>
                    <Description>
                        Têm início com o processo de criação de leis e a
                        apresentação de projetos no Poder Legislativo. Na
                        apreciação de matérias, podem haver eventuais conflitos
                        de interpretação ou de entendimento entre o que
                        estabelece o Regimento Interno da Casa e a Lei Orgânica
                        do Município. Nestes casos, prevalece a Lei Orgânica.
                    </Description>
                </Info>
            </Card>
            <Card to="painel/vereadores">
                <ContainerIcon>
                    <Icon
                        className="icon-pessoal"
                        size="45px"
                        color={colors.color_primary}
                    />
                </ContainerIcon>
                <Info>
                    <Title>Vereadores</Title>
                    <Description>
                        O Poder Legislativo, exercido pelo sistema de
                        representação, tem nos parlamentares a sua expressão
                        máxima. Devem transformar os anseios de seus
                        representados em ações diretas, na forma de leis ou
                        buscando junto do Executivo obras e atos que beneficiem
                        a sua comunidade. Possuem funções legisladora,
                        administrativa, julgadora e de fiscalização sobre a
                        conduta do Executivo.
                    </Description>
                </Info>
            </Card>
        </Container>
    );
}

export default Home;
