import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';

import { Field, Formik } from 'formik';
import moment from 'moment';

import { Row } from '../../../app/global-styles';
import { error, success } from '../../../components/alerts';
import confirmAlert from '../../../components/confirm-alert';
import ControlledInput from '../../../components/form-components/controlled-input';
import Icon from '../../../components/icon';
import PageContainer from '../../../components/page-container';
import Table from '../../../components/table';
import { colors } from '../../../configs/theme';
import Request, { getLimit } from '../../../utils/Request';
import 'moment/locale/pt-br';

moment.locale('pt-br');

function Sessoes(props) {
    const history = useHistory();
    const tableRef = useRef();
    const [searchParams, setSearchParams] = useState({});

    const initialFilters = {
        search: '',
    };

    useEffect(() => {
        tableRef.current.fireFetchData();
    }, [searchParams]);

    async function getSessoes({ page, limit: table_limit, ...table_props }) {
        const request = new Request();

        const limit = getLimit(page, table_limit);

        const req_sessoes = request.setRequest('sessoes', 'listar', {
            limit,
            ...searchParams,
        });
        const result = await request.execute();

        if (result && result[req_sessoes]) {
            return result[req_sessoes];
        }
        return {};
    }

    async function desativarSessao({ original }) {
        if (original && original.id) {
            const desativar = async () => {
                const request = new Request();

                const { id } = original;

                const req_desativar = request.setRequest(
                    'sessoes',
                    'desativar',
                    { id }
                );
                const result = await request.execute();

                if (result[req_desativar] === true) {
                    success('Sessão excluída com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir Sessão!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir a sessão?',
                subtitle: `Ao confirmar a sessão ${original.numero} será excluída!`,
                onConfirm() {
                    desativar(original).then();
                },
            });
        } else {
            error('Sessão inválida!');
        }
    }

    function makeFormFiltros(formikState) {
        return (
            <Row as="form" wrap="wrap">
                <Field
                    name="search"
                    type="text"
                    placeholder="Filtrar"
                    size={1}
                    minWidth={210}
                    onChange={(e) => {
                        const { value, name } = e.target;
                        setSearchParams({ ...searchParams, [name]: value });
                    }}
                    icon="icon-lupa"
                    kind="filtros"
                    show_filtros={false}
                    component={ControlledInput}
                />
                {Object.keys(searchParams).some(
                    (key) => searchParams[key] && searchParams[key] !== ''
                ) && (
                    <Icon
                        className="icon-limpar"
                        size="26px"
                        color={colors.grey_placeholder}
                        title="Limpar filtros"
                        onClick={() => {
                            setSearchParams({});
                            formikState.resetForm({ ...initialFilters });
                        }}
                        hover={colors.white}
                        marginLeft="15px"
                        kind="action-button"
                    />
                )}
            </Row>
        );
    }

    return (
        <PageContainer padding scroll>
            <Row margin="0 0 21px 0">
                <Formik initialValues={{ ...initialFilters }}>
                    {makeFormFiltros}
                </Formik>
            </Row>
            <Table
                headers={[
                    {
                        name: 'Sessão',
                        accessor: 'nome_sessao',
                        minWidth: 75,
                    },
                    {
                        name: 'Data',
                        accessor: 'data',
                        Cell: (props) => (
                            <>
                                {props.value &&
                                    props.value
                                        .split('-')
                                        .reverse()
                                        .join('/')}
                            </>
                        ),
                        minWidth: 25,
                    },
                ]}
                options={(table_props) => (
                    <Icon
                        hidden
                        size="16px"
                        hover={colors.red_error_message}
                        color={colors.black_table}
                        className="icon-trash"
                        onClick={() => desativarSessao(table_props)}
                    />
                )}
                tableRef={tableRef}
                data_function={getSessoes}
                clickHandler={(row, column) => {
                    if (
                        column.id !== 'options' &&
                        row &&
                        row.original &&
                        row.original.id
                    ) {
                        history.push(
                            `/painel/sessoes/editor/id=${row.original.id}`
                        );
                    }
                }}
            />
        </PageContainer>
    );
}

export default Sessoes;
