import React from 'react';

import { colors } from '../../../configs/theme';
import Icon from '../../icon';
import { Container, ContainerIcon, Descricao } from './styles';

function InputUpload({
    isDragActive,
    isDragAccept,
    isDragReject,
    drop_message,
    forbidden_message,
    upload_message,
}) {
    const getIconColor = (propsStatus) => {
        if (propsStatus.isDragAccept) {
            return colors.primary_green;
        }
        if (propsStatus.isDragReject) {
            return colors.red;
        }

        return '#cccccc';
    };

    function getMessage() {
        if (isDragActive && isDragAccept) {
            return <Descricao>{drop_message}</Descricao>;
        }

        if (isDragReject) {
            return <Descricao>{forbidden_message}</Descricao>;
        }

        return <Descricao>{upload_message}</Descricao>;
    }

    return (
        <Container>
            <ContainerIcon
                isDragAccept={isDragAccept}
                isDragReject={isDragReject}
            >
                <Icon
                    className="icon-upload"
                    size="109px"
                    color={getIconColor({ isDragAccept, isDragReject })}
                />
            </ContainerIcon>
            {getMessage()}
        </Container>
    );
}

export default InputUpload;
