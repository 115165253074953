import React from 'react';

import PropTypes from 'prop-types';

import ErrorMessage from '../error-message';
import { Label, LabelMessage, Required } from '../styles';
import { Container, GroupContainer, InputRadio } from './styles';

function RadioGroup({
    name,
    id,
    width,
    label,
    buttonstyle,
    error_message,
    required,
    options,
    onChange,
    ignoremobile,
    fullWidth,    value,
    disabled,
    margin,
    ...props
}) {
    function getChecked(fieldValue, inputValue) {
        if (!isNaN(Number(fieldValue))) {
            return Number(fieldValue) === Number(inputValue);
        }
        return fieldValue === inputValue;
    }

    return (
        <Container fullWidth={fullWidth} width={width}>
            {label && (
                <Label>
                    <LabelMessage>
                        {label}
                        {required && <Required>*</Required>}
                    </LabelMessage>
                </Label>
            )}
            <GroupContainer>
                <>
                    {options.map((option) => (
                        <InputRadio
                            key={option.value}
                            checked={getChecked(value, option.value)}
                            show_error={!!error_message}
                            margin={margin}
                            ignoreMobile={ignoremobile}
                            title={option.title ?? option.label}
                            buttonstyle={buttonstyle}
                        >
                            <input
                                type="radio"
                                disabled={disabled}
                                name={name}
                                value={option.value}
                                onChange={(event) =>
                                    onChange && onChange(event)
                                }
                                checked={value === option.value}
                            />
                            <span />
                            <p>{option.label}</p>
                        </InputRadio>
                    ))}
                </>
            </GroupContainer>
            {error_message && <ErrorMessage error_message={error_message} />}
        </Container>
    );
}

export default RadioGroup;

RadioGroup.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    error_message: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    buttonstyle: PropTypes.bool,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    margin: PropTypes.string,
};

RadioGroup.defaultProps = {
    name: null,
    id: null,
    value: '',
    width: '100%',
    label: null,
    error_message: null,
    required: false,
    margin: '0 15px 10px 0',
    buttonstyle: false,
    onChange: null,
    disabled: false,
    fullWidth: false,
};
