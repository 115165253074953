import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import { error, success } from '../../../../components/alerts';
import RadioGroup from '../../../../components/form-components/radio-group';
import Popup from '../../../../components/popup';
import Table from '../../../../components/table';
import Request, { getLimit } from '../../../../utils/Request';

function Votacao({
    open,
    setOpen,
    onClose,
    votacaoPayload,
    setVotacaoPayload,
}) {
    const tableRef = useRef();

    async function getVereadores({ page, limit: table_limit }) {
        const request = new Request();

        const limit = getLimit(page, table_limit);

        const req_vereadores = request.setRequest(
            'votacoes_voto',
            'listarVereadores',
            {
                limit,
                votacao_id: votacaoPayload.votacao_id,
                sessao_id: votacaoPayload.sessao_id,
            }
        );

        const result = await request.execute();

        if (result && result[req_vereadores]) {
            return result[req_vereadores];
        }
        return {};
    }

    async function handleVotoChange({ vereador_id, votacao_id, voto }) {
        if (vereador_id && votacao_id && voto) {
            const request = new Request();
            const req_votacao_voto = request.setRequest(
                'votacoes_voto',
                'salvar',
                {
                    vereador_id,
                    votacao_id,
                    voto,
                }
            );

            const result = await request.execute();

            if (
                result &&
                result[req_votacao_voto] &&
                result[req_votacao_voto] !== false
            ) {
                success('Voto alterado com sucesso!');
                tableRef.current.fireFetchData();
            } else {
                error('Não foi possível alterar o voto do vereador!');
            }
        } else {
            error('Verador inválido!');
        }
    }

    return (
        <Popup
            height="600px"
            title={`Votação de: ${votacaoPayload.descricao}`}
            open={open}
            onOpen={(stopLoading) => {
                stopLoading();
            }}
            onClose={() => {
                if (onClose) {
                    onClose();
                }
                setOpen(false);
            }}
            contentOverflow
        >
            <Table
                headers={[
                    {
                        accessor: 'nome',
                        name: 'Vereador',
                    },
                    {
                        accessor: 'voto',
                        name: 'Voto',
                        Cell: (cellProps) => {
                            const {
                                original: {
                                    id: vereador_id,
                                    voto,
                                    voto_descricao,
                                },
                            } = cellProps;

                            const selectedValue =
                                voto && voto_descricao ? voto : null;

                            return (
                                <RadioGroup
                                    buttonstyle
                                    name="voto_radio"
                                    size={1}
                                    value={selectedValue}
                                    fullWidth
                                    onChange={async (value) => {
                                        const updated = await handleVotoChange({
                                            vereador_id,
                                            votacao_id:
                                                votacaoPayload.votacao_id,
                                            voto: value.target.value || null,
                                        });

                                        if (updated) {
                                            tableRef.current.fireFetchData();
                                        }
                                    }}
                                    margin="0"
                                    options={[
                                        {
                                            label: 'Favorável',
                                            value: 'S',
                                        },
                                        {
                                            label: 'Contrário',
                                            value: 'N',
                                        },
                                    ]}
                                />
                            );
                        },
                    },
                ]}
                tableRef={tableRef}
                data_function={getVereadores}
            />
        </Popup>
    );
}

Votacao.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    votacaoPayload: PropTypes.shape({
        descricao: PropTypes.string,
        votacao_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sessao_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    setVotacaoPayload: PropTypes.func.isRequired,
    onClose: PropTypes.func,
};

export default Votacao;
