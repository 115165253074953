import React, { useState, useContext, createContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import Error from '../../components/error';
import FooterCidadao from '../../components/footer';
import { AppContext } from '../app-container';
import { AppContent, Container } from './styles';

export const CidadaoContext = createContext();

function Cidadao() {
    const { client } = useContext(AppContext);

    const searchParamsInitialValues = {
        orgao: {},
        tipo: {},
        filtrar: '',
        periodo: null,
    };

    const [searchParams, setSearchParams] = useState({
        ...searchParamsInitialValues,
    });

    return (
        <CidadaoContext.Provider
            value={{
                searchParams,
                setSearchParams,
                searchParamsInitialValues,
            }}
        >
            <Route>
                <Container>
                    <AppContent>
                        <Switch>
                            <Route component={Error} />
                        </Switch>
                    </AppContent>
                    <FooterCidadao instituicao={client.nome} />
                </Container>
            </Route>
        </CidadaoContext.Provider>
    );
}

export default Cidadao;
