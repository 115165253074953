import React from 'react';

import PropTypes from 'prop-types';

import { Container, Title } from './styles';

function PageContainer({ title, children, scroll, ...props }) {
    return (
        <Container {...props}>
            {title && <Title>{title}</Title>}
            {children}
        </Container>
    );
}

PageContainer.propTypes = {
    title: PropTypes.string,
    scroll: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

PageContainer.defaultProps = {
    title: null,
    scroll: false,
};

export default PageContainer;
