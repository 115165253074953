import axios from 'axios';

import { URL_API } from '../data/constants';

export const api = axios.create({
    baseURL: `${URL_API}/api`,
});

export const uploadAPI = (params, onUploadProgressCb, acao = 'enviarOutros') =>
    api.post(`midias?acao=${acao}`, params, {
        onUploadProgress: onUploadProgressCb,
    });

export function uploadFile(file, onUploadProgressCb) {
    const data = new FormData();

    data.append('Filedata', file, file.name);

    return uploadAPI(data, (event) => {
        const progress = Math.round((event.loaded * 100) / event.total);
        onUploadProgressCb(progress, event);
    });
}

export const uploadImageAPI = (
    params,
    onUploadProgressCb,
    acao = 'enviarImg'
) =>
    api.post(`midias?acao=${acao}`, params, {
        onUploadProgress: onUploadProgressCb,
    });

export function uploadImage(file, onUploadProgressCb) {
    const data = new FormData();

    data.append('Filedata', file, file.name);

    return uploadImageAPI(data, (event) => {
        const progress = Math.round((event.loaded * 100) / event.total);
        onUploadProgressCb(progress, event);
    });
}
