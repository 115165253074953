import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Row } from '../../app/global-styles';
import { error, success } from '../alerts';
import Button from '../button';
import Popup from '../popup';
import Reordable, { listOrdered, listParse } from '../reordable';

function ReorderPopup({
    getData,
    handleReorder,
    dataInfo,
    successMessage,
    errorMessage,
}) {
    const [reorableList, setReorableList] = useState();

    async function reorder() {
        if (reorableList) {
            const reordered = await handleReorder(listOrdered(reorableList));

            if (reordered) {
                return reordered;
            }
            return false;
        }
        return false;
    }

    return (
        <Popup
            height="600px"
            title="Reordenar Anexos"
            trigger={() => (
                <Row margin="0 0 5px 5px" width="auto">
                    <Button position="relative" kind="reorder">
                        Reordenar
                    </Button>
                </Row>
            )}
            contentOverflow
            onOpen={(setLoading) => {
                async function requestDados() {
                    try {
                        const data = await getData();

                        setReorableList(
                            listParse(
                                data.dados,
                                dataInfo.value,
                                dataInfo.label
                            )
                        );
                        setLoading(false);
                    } catch (e) {
                        setLoading(false);
                    }
                }

                requestDados().then();
            }}
            buttons={(close) => (
                <>
                    <Button kind="cancel" onClick={close} margin="0 10px 0 0">
                        Cancelar
                    </Button>
                    <Button
                        kind="save"
                        onClick={() => {
                            async function reorderData() {
                                const reordenados = await reorder();

                                if (
                                    reordenados &&
                                    typeof reordenados !== 'string'
                                ) {
                                    success(successMessage);
                                    close();
                                } else {
                                    error(errorMessage);
                                }
                            }

                            reorderData().then();
                        }}
                    >
                        Salvar
                    </Button>
                </>
            )}
        >
            <Reordable list={reorableList} setList={setReorableList} />
        </Popup>
    );
}

ReorderPopup.propTypes = {
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    dataInfo: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    }).isRequired,
    getData: PropTypes.func.isRequired,
    handleReorder: PropTypes.func.isRequired,
};

ReorderPopup.defaultProps = {
    successMessage: 'Anexos reordenados com sucesso!',
    errorMessage: 'Não foi possível reordenar anexos',
};

export default ReorderPopup;
