import PropTypes from 'prop-types';
import styled, { createGlobalStyle, css } from 'styled-components';

import { colors, fonts, sizes } from '../configs/theme';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        border: 0;
        color: inherit;
        text-decoration: none;
        outline: none;
        box-sizing: border-box;
        box-shadow: none;
    }

    ::before, ::after {
        box-sizing: border-box;
        text-rendering: optimizeLegibility;
    }

    ::selection {
        background: ${colors.color_pink_swan_approx};
        color: ${colors.color_log_cabin_approx};
    }

    div:after,
    header:after,
    section:after,
    article:after,
    aside:after,
    form:after,
    menu:after,
    nav:after,
    ul:after,
    ol:after,
    li:after {
        display: block;
        clear: both;
        height: 0;
        overflow: hidden;
    }

    button::-moz-focus-inner,
    input[type="reset"]::-moz-focus-inner,
    input[type="button"]::-moz-focus-inner,
    input[type="button"]::-moz-focus-inner,
    input[type="submit"]::-moz-focus-inner,
    input[type="file"] > input[type="button"]::-moz-focus-inner {
        border: none;
    }

    input, textarea, select, [contenteditable] {
        font-size: ${sizes.font_size_base}px;
        font-family: ${fonts.font_principal}, ${fonts.font_secundaria};
        text-rendering: optimizeLegibility;
    }

    html, body, #root {
        position: relative;
        width: 100%;
        height: 100%;
    }

    body {
        font-size: ${sizes.font_size_base}px;
        font-family: ${fonts.font_principal}, ${fonts.font_secundaria};
        color: ${colors.color_emperor_approx};
        background: ${colors.primary_grey};
        text-rendering: optimizeLegibility;
    }
`;

const barOrientation = (orientation) => {
    if (orientation === 'horizontal') {
        return css`
            border-radius: 4px 4px 0 0;
            width: 100%;
            height: 4px;
        `;
    }
    return css`
        border-radius: 0 4px 4px 0;
        width: 4px;
        height: 100%;
    `;
};

export const Bar = styled('div')`
    background-color: ${colors.color_primary};
    ${(props) => (props.orientation ? barOrientation(props.orientation) : null)}
    ${(props) =>
        props.absolute &&
        css`
            position: absolute;
        `}
    ${(props) =>
        props.left &&
        css`
            left: 0;
        `}
    ${(props) =>
        props.bottom &&
        css`
            bottom: 0;
        `}
    ${(props) =>
        props.textWidth &&
        css`
            width: calc(100% - 30px);
            left: 15px;
        `}
    ${(props) =>
        props.first &&
        css`
            left: 0;
            width: calc(100% - 15px);
        `}
`;

export const Row = styled('div')`
    display: flex;
    width: ${(props) =>
        props.width
            ? css`
                  ${props.width}
              `
            : '100%'};
    height: ${(props) => (props.height ? props.height : 'auto')};
    ${(props) =>
        props.relative &&
        css`
            position: relative;
        `}
    ${(props) =>
        props.spaceBetween &&
        css`
            justify-content: space-between;
        `}
    ${(props) =>
        props.contentEnd &&
        css`
            justify-content: flex-end;
        `}
    ${(props) =>
        props.padding &&
        css`
            padding: ${props.padding};
        `}
    ${(props) =>
        props.margin &&
        css`
            margin: ${props.margin};
        `}
    ${(props) =>
        props.wrap &&
        css`
            flex-wrap: ${props.wrap};
        `}
    ${(props) =>
        props.zIndex &&
        css`
            z-index: ${props.zIndex};
        `}
    ${(props) =>
        props.alignItems &&
        css`
            align-items: ${props.alignItems};
        `}
`;

Row.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    relative: PropTypes.bool,
    spaceBetween: PropTypes.bool,
    alignItems: PropTypes.string,
    contentEnd: PropTypes.bool,
    padding: PropTypes.string,
    margin: PropTypes.string,
    wrap: PropTypes.string,
    zIndex: PropTypes.number,
};
