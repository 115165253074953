import React from 'react';

import PropTypes from 'prop-types';

import CheckBoxGroup from '../checkbox-group';
import DatePicker from '../date-picker';
import DateTimePicker from '../date-time-picker';
import DateRangePicker from '../daterange-picker';
import Editor from '../editor-wysiwyg';
import Geolocation from '../geolocation';
import Input from '../input';
import Mask from '../mask';
import RadioGroup from '../radio-group';
import Select from '../select';
import Slider from '../slider';
import TableSearchInput from '../table-search-input';

function ControlledInput({
    type,
    size,
    ignoreTypeChange,
    onChange,
    onBlur,
    maskedValue,
    onFocus,
    type_select,
    indexError,
    height,
    margin,
    id,
    inPopup,
    background_color,
    form,
    field,
    indexField,
    ...props
}) {
    const { errors, touched } = form;
    const { name, value } = field;

    props.width = props.width || `calc((100% / 8) * ${size})`;

    const general_events = {
        onChange(event, editor = null) {
            if (!ignoreTypeChange) {
                if (type === 'editor') {
                    form.setFieldValue(name, editor.getData());
                } else if (type === 'select') {
                    if (props.isMulti && event === null) {
                        form.setFieldValue(name, []);
                    } else {
                        form.setFieldValue(name, event);
                    }
                } else if (
                    type === 'date-picker' ||
                    type === 'year-picker' ||
                    type === 'date-range'
                ) {
                    form.setFieldValue(name, event);
                } else if (type === 'mask') {
                    const input_value = event.target.value;
                    form.setFieldValue(name, input_value);
                } else if (type === 'checkbox-group') {
                    form.setFieldValue(name, event);
                } else if (type === 'input-radio') {
                    form.setFieldValue(name, event.target.value);
                } else if (type === 'slider') {
                    form.setFieldTouched(name, true);
                    form.setFieldValue(name, event);
                }
            }

            if (field.onChange) {
                field.onChange(event);
            }

            if (onChange && type === 'editor') {
                onChange(editor.getData());
            } else if (onChange && type === 'input-radio') {
                onChange(event.target.value);
            } else if (onChange) {
                onChange(event);
            }
        },
        onBlur(event, editor = null) {
            if (onBlur && type !== 'editor') {
                onBlur(event);
            }

            if (field.onBlur && type !== 'editor' && type !== 'select') {
                field.onBlur(event);
            }

            if (type === 'editor') {
                form.setFieldTouched(name, true);
            } else if (
                type === 'select' ||
                type === 'date-picker' ||
                type === 'year-picker' ||
                type === 'datetime-picker' ||
                type === 'mask' ||
                type === 'checkbox-group' ||
                type === 'input-radio'
            )
                form.setFieldTouched(name, true);
        },
        onFocus(event, editor = null) {
            if (onFocus) {
                onFocus(event);
            }

            if (field.onFocus) {
                field.onFocus(event, editor);
            }
        },
    };

    const field_props = {
        height,
        margin,
        name,
        value,
        id,
        type,
        background_color,
        ...props,
        ...field,
        ...general_events,
    };

    if (inPopup) {
        field_props.kind = 'popup-filtros-input';
    }

    if (maskedValue !== undefined) {
        field_props.value = maskedValue;
    }

    if (errors[name] && touched[name]) {
        field_props.error_message = errors[name];
    } else if (indexField !== undefined) {
        const multi_field_name = name.split('.')[0];

        if (
            multi_field_name &&
            errors[indexError] &&
            errors[indexError][indexField] &&
            touched[indexError] &&
            touched[indexError][indexField]
        ) {
            field_props.error_message =
                errors[indexError][indexField][multi_field_name];
        }
    }

    if (type === 'select') {
        field_props.type_select = type_select;
    }

    if (type === 'select') {
        return <Select {...field_props} />;
    }

    if (type === 'date-picker') {
        return <DatePicker {...field_props} />;
    }

    if (type === 'year-picker') {
        return (
            <DatePicker
                {...field_props}
                maxDetail="decade"
                minDetail="century"
            />
        );
    }

    if (type === 'datetime-picker') {
        return <DateTimePicker {...field_props} />;
    }

    if (type === 'editor') {
        return <Editor {...field_props} />;
    }

    if (type === 'mask') {
        return <Mask {...field_props} />;
    }

    if (type === 'checkbox-group') {
        return <CheckBoxGroup {...field_props} />;
    }

    if (type === 'input-radio') {
        return <RadioGroup {...field_props} />;
    }

    if (type === 'date-range') {
        return <DateRangePicker {...field_props} />;
    }

    if (type === 'geolocation') {
        return (
            <Geolocation {...field_props} setFieldValue={form.setFieldValue} />
        );
    }

    if (type === 'slider') {
        return <Slider {...field_props} />;
    }

    if (type === 'table-search') {
        return <TableSearchInput {...field_props} />;
    }

    return <Input {...field_props} />;
}

ControlledInput.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    indexError: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.oneOf([
        'slider',
        'text',
        'editor',
        'select',
        'datetime-picker',
        'date-picker',
        'year-picker',
        'mask',
        'password',
        'table-search',
        'checkbox-group',
        'input-radio',
        'date-range',
        'geolocation',
        'repeater',
    ]).isRequired,
    type_select: PropTypes.oneOf([
        'async',
        'default',
        'async_creatable',
        'creatable',
    ]),
    defaultOptions: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.bool,
    ]),
    maskedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
        PropTypes.bool,
    ]),
    mask: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
    cacheOptions: PropTypes.bool,
    inPopup: PropTypes.bool,
    ignoreTypeChange: PropTypes.bool,
    loadOptions: PropTypes.func,
    mountcallback: PropTypes.func,
    options: PropTypes.array,
};

ControlledInput.defaultProps = {
    size: 4,
    ignoreTypeChange: false,
    inPopup: false,
    mountcallback: null,
};

export default ControlledInput;
