import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

export const Container = styled('aside')`
    width: 180px;
    background: ${colors.color_primary_dark};
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Menu = styled('div')`
    width: 100%;
    margin-top: 20px;
    font-size: ${parse(16)};
    height: 50px;
    padding: 7px 21px;
    color: ${colors.white};
    align-items: center;
    background-color: ${colors.color_primary};
    display: flex;
`;

export const NavMenu = styled('nav')`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

export const LinkContainer = styled('div')`
    padding: 7px 17px;
    color: ${colors.white};
    font-size: ${parse(16)};
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    ${(props) =>
        !props.active &&
        css`
            margin-left: 4px;
        `}
`;

export const StyledLink = styled(Link)`
    width: 100%;
    min-height: 45px;
    align-items: center;
    display: flex;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        border-radius: 0 4px 4px 0;
        background-color: transparent;
    }

    &:hover {
        background-color: ${colors.table_top};
    }

    ${(props) =>
        props.active &&
        css`
            ${LinkContainer} {
                width: calc(100% - 4px);
                margin-left: 0;
            }
            background-color: ${colors.table_top};

            &:before {
                background-color: ${colors.color_primary};
            }
        `}
`;
