import React, { useEffect } from 'react';

import Input from '../input';

function Geolocation({ setFieldValue, ...props }) {
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;

                setFieldValue(props.name, `${latitude},${longitude}`);
            },
            (err) => {
                console.log(err);
            },
            {
                timeout: 30000,
            }
        );
    }, []);

    return <Input {...props} type="text" />;
}

export default Geolocation;
