/*
 * Informações da aplicação mutaveis em tempo de execução
 */

import pkg from '../../package.json';
import { BASE, HAS_BASE } from './constants';

/**
 * Informações setadas durante a execuçãp
 * @type {{}}
 */
const AppData = {
    version: pkg.version,
};
export default AppData;

/**
 * O mesmo que a base mas sem o host
 *
 * @return {string}
 */
export const basePath = () => {
    const { host } = window.location;
    const str_base = BASE;

    let basepath = '/';
    if (HAS_BASE) {
        [basepath] = str_base.split(host).slice(-1);
        basepath = basepath.replace(/[/]+$/, '') || '/';
    }

    return basepath;
};

/**
 * O restante do caminho após a base da URL
 *
 * @return {string}
 */
export const pathName = () => {
    const { pathname } = window.location;

    if (HAS_BASE) {
        const basepath = basePath();

        let newpathname = '/';
        if (basepath !== '/') {
            [newpathname] = pathname.split(basepath).slice(-1);
            newpathname = newpathname.replace(/[/]+$/, '') || '/';
        }

        return newpathname;
    }
    return pathname;
};

/**
 * Verifica se a URL é do painel
 *
 * @param {string} [url] Se não for informada pega por padrão o pathname
 *
 * @return {boolean}
 */
export const isPainel = (url) => {
    const path = url || pathName();

    return path === '/painel' || path.startsWith('/painel/');
};

// host: "192.168.254.221:3000"
// hostname: "192.168.254.221"
// href: "http://192.168.254.221:3000/"
// origin: "http://192.168.254.221:3000"
// pathname: "/"
// port: "3000"
// protocol: "http:"

// host: "localhost"
// hostname: "localhost"
// href: "http://localhost/legisweb/client/build/painel/lei?aaaa"
// origin: "http://localhost"
// pathname: "/legisweb/client/build/painel/lei"
// port: ""
// protocol: "http:"

// console.log(window.location);
// console.log('BASE', BASE);
// console.log('BASE_PATH', basePath());
// console.log('PATHNAME', pathName());
