import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';

export const Container = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
`;

export const Card = styled(Link)`
    width: calc(50% - 10px);
    height: calc(50% - 10px);
    background-color: ${colors.white};
    border-radius: 5px;
    padding: 20px;
    display: flex;
    margin: 0 20px 20px 0;

    &:nth-child(2n) {
        margin-right: 0;
    }

    &:nth-child(n + 3) {
        margin-bottom: 0;
    }
`;

export const ContainerIcon = styled('div')`
    min-width: 90px;
    height: 90px;
    border-radius: 5px;
    background-color: ${colors.color_hover_table};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    flex-grow: 1;
`;

export const Info = styled('div')`
    width: auto;
    flex-grow: 1;
    height: 100%;
    color: ${colors.text_gray_default};
    padding-top: 12px;
    overflow: auto;
`;

export const Title = styled('h2')`
    font-size: ${parse(20)};
    font-weight: bold;
    margin-bottom: 15px;
`;

export const Description = styled('p')`
    font-size: ${parse(14)};
    line-height: ${parse(20)};
`;
