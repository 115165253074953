import styled from 'styled-components';

import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';

export const ProgressBar = styled('div')`
    background-color: ${colors.primary_green};
    width: ${(props) => props.progress}%;
    transition: 0.2s;
    position: absolute;
    justify-self: flex-end;
    height: 6px;
    border-radius: ${(props) =>
        props.progress && Number(props.progress) === 100
            ? '0 0 6px 6px'
            : '0 0 0 6px'};
    bottom: 0;
    left: 0;
`;

export const Container = styled('div')`
    width: 100%;
    height: 55px;
    position: relative;
    background-color: ${colors.white};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: 5px;
`;

export const Filename = styled('p')`
    font-size: ${parse(14)};
    font-weight: bold;
    color: ${(props) => (props.accepted ? '#000' : colors.red_required)}};
`;

export const Filesize = styled('p')`
    font-size: ${parse(12)};
    color: #5c5c5c;
    margin-top: 5px;
`;
