import React from 'react';
import { confirmAlert as reactConfirmAlert } from 'react-confirm-alert';

import PropTypes from 'prop-types';

import { Container, Header, Title, Content, Close, SubTitle } from './styles';
import './styles.css';
import { colors } from '../../configs/theme';
import Icon from '../icon';
import Button from '../button';
import { Row } from '../../app/global-styles';

ConfirmAlert.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    closeOnClickOutside: PropTypes.bool,
    closeOnEscape: PropTypes.bool,
};

function ConfirmAlert({ title, subtitle, onConfirm, ...props }) {
    const attrs = {
        confirmLabel: 'Confirmar',
        cancelLabel: 'Cancelar',
        closeOnClickOutside: true,
        closeOnEscape: true,
        ...props,
    };

    const { confirmLabel, cancelLabel } = attrs;

    reactConfirmAlert({
        customUI: (confirm_props) => (
            <Container>
                <Header>
                    <Title>{title}</Title>
                    {subtitle && <SubTitle>{subtitle}</SubTitle>}
                    <Close onClick={confirm_props.onClose}>
                        <Icon
                            className="icon-close"
                            size="14px"
                            color={colors.primary_green}
                        />
                    </Close>
                </Header>
                <Content>
                    <Row contentEnd>
                        <Button
                            margin="0 10px 0 0"
                            kind="cancel"
                            onClick={confirm_props.onClose}
                        >
                            {cancelLabel}
                        </Button>
                        <Button
                            kind="save"
                            onClick={() => {
                                onConfirm();
                                confirm_props.onClose();
                            }}
                        >
                            {confirmLabel}
                        </Button>
                    </Row>
                </Content>
            </Container>
        ),
        ...attrs,
    });
}

export default ConfirmAlert;
