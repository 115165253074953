import React from 'react';

import AppContainer from './app-container';
import GlobalStyle from './global-styles';

function App() {
    return (
        <>
            <GlobalStyle />
            <AppContainer />
        </>
    );
}

export default App;
