import { extractParamsAsString } from '../utils/RouterRes';

export const groups = {
    painel: [
        {
            title: 'Página inicial',
            to: '/painel',
            permissao: 'R_DIARIOS',
        },
    ],
    comissoes: [
        {
            title: 'Lista de Comissões',
            to: '/painel/comissoes',
            permissao: 'R_DIARIOS',
        },
        {
            title: 'Adicionar Comissão',
            to: '/painel/comissoes/editor',
            permissao: 'M_DIARIOS',
        },
    ],
    legislaturas: [
        {
            title: 'Lista de Legislaturas',
            to: '/painel/legislaturas',
            permissao: 'R_DIARIOS',
        },
        {
            title: 'Adicionar Legislatura',
            to: '/painel/legislaturas/editor',
            permissao: 'M_DIARIOS',
        },
    ],
    materias: [
        {
            title: 'Lista de Matérias',
            to: '/painel/materias',
            permissao: 'R_DIARIOS',
        },
        {
            title: 'Adicionar Matéria',
            to: '/painel/materias/editor',
            permissao: 'M_DIARIOS',
        },
    ],
    regioes: [
        {
            title: 'Estados',
            to: '/regioes/estados',
            permissao: 'R_MATERIAS',
        },
        {
            title: 'Cidades',
            to: '/regioes/cidades',
            permissao: 'M_MATERIAS',
        },
    ],
    sessoes: [
        {
            title: 'Lista de sessões',
            to: '/painel/sessoes',
            permissao: 'R_DIARIOS',
        },
        {
            title: 'Adicionar Sessão',
            to: '/painel/sessoes/editor',
            permissao: 'M_DIARIOS',
        },
    ],
    usuarios: [
        {
            title: 'Lista de usuários',
            to: '/painel/usuarios',
            permissao: 'R_DIARIOS',
        },
    ],
    vereadores: [
        {
            title: 'Vereadores',
            to: '/painel/vereadores',
            permissao: 'R_DIARIOS',
        },
        {
            title: 'Adicionar Vereador',
            to: '/painel/vereadores/editor',
            permissao: 'M_DIARIOS',
        },
    ],
};

const selectedTabs = (group, full_url) => {
    full_url = full_url.replace(/\/id=\d*/, '');

    if (group.length > 0) {
        return group.map((tab) => ({
            ...tab,
            selected: tab.to === full_url,
        }));
    }

    return [];
};

const getParams = (pathname, url) => {
    let params_string = extractParamsAsString(pathname, url);

    params_string =
        params_string === '' ? pathname.replace(/^[/]+/, '') : params_string;

    const params = params_string.split('/');

    const group_name = params[0] ? params[0] : '';
    const group_param = params[0] ? `/${params[0]}` : '';
    const action_param = params[1] ? `/${params[1]}` : '';

    return {
        group_name,
        group_param,
        action_param,
    };
};

const getGroup = (group_name) => {
    const group_index = Object.keys(groups).find(
        (group) => group === group_name
    );

    if (groups[group_index] && groups[group_index].length > 0) {
        return groups[group_index];
    }

    return [];
};

export const getTabGroup = (pathname, url) => {
    const { group_name, group_param, action_param } = getParams(pathname, url);
    const full_url = `${url}${group_param}${action_param}`;

    const group = getGroup(group_name);

    return selectedTabs(group, full_url);
};

export const getGroupInfo = (menu = [], pathname, url, painel) => {
    let { group_param } = getParams(pathname, url);

    group_param =
        painel && group_param !== '/painel'
            ? `/painel${group_param}`
            : group_param;

    return menu.find((item) => item.link === group_param);
};

export const getPathPage = (menu, group, tabs, actual_url, painel) => {
    const splitted = group.link.split('/');

    if (splitted && !splitted[1] && splitted[1] === '') {
        splitted.pop();
    }

    if (splitted && splitted[0] === '') {
        splitted.shift();
    }

    const url = painel ? '/painel' : '/';

    const pathItens = splitted.map((item) => {
        item = painel ? `painel/${item}` : item;

        const groupInfo = getGroupInfo(menu, `/${item}`, url, painel);

        return {
            link: groupInfo.link,
            title: groupInfo.title,
        };
    });
    const { group_name } = getParams(group.link, url);
    const actualGroup = getGroup(group_name);
    const actualTab = selectedTabs(actualGroup, actual_url).find(
        (tab) => tab.selected
    );

    if (actualTab) {
        pathItens.push({
            link: actualTab.to,
            title: actualTab.title,
        });
    }

    return pathItens;
};

export const isSomeActive = (requested_group, pathname, url) => {
    const { group_name } = getParams(pathname, url);
    const { group_name: requested_group_name } = getParams(
        requested_group,
        url
    );

    if (group_name === requested_group_name) {
        const group = selectedTabs(getGroup(group_name), requested_group);
        return group.some((item) => item.selected);
    }
    return false;
};
