import React from 'react';

import icp_green from '../../assets/img/icp-green.png';
import { StyledFooter, Container, Bloco, ICP } from './styles';

function Footer(props) {
    const { instituicao } = props;

    return (
        <StyledFooter>
            <Container>
                <Bloco>
                    <p>
                        ©{' '}
                        <b>
                            {new Date().getFullYear()}. {instituicao}.
                        </b>{' '}
                        Todos os direitos reservados.
                    </p>
                    <p>
                        {' '}
                        Sistema desenvolvido por{' '}
                        <a href="https://nucleogov.com.br/" target="_blank">
                            NúcleoGov
                        </a>
                    </p>
                </Bloco>
                <Bloco>
                    <p>
                        Os Atos oficiais publicados neste site são assinados
                        digitalmente
                        <br />
                        por autoridade certificadora credenciada no âmbito da
                        <br />
                        Infraestrutura de Chaves Públicas Brasileira - ICP
                        Brasil.
                    </p>
                    <ICP src={icp_green} />
                </Bloco>
            </Container>
        </StyledFooter>
    );
}

export default Footer;
