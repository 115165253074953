import React, { useState } from 'react';

import logo from '../../../assets/img/logo branca.png';
import { error } from '../../../components/alerts';
import AppData from '../../../data/AppData';
import Request from '../../../utils/Request';
import {
    StyledLogin,
    Form,
    Imgs,
    Logo,
    Versao,
    InputContainer,
    Input,
    Entrar,
    Remember,
    Switch,
    Slider,
    StyledCheckBox,
    Conectado,
} from './styles';

function Login() {
    const [login_data, setLoginData] = useState({
        login: '',
        senha: '',
        lembrar: false,
    });

    function handleInput(e) {
        const input = e.target.name;
        const { value } = e.target;
        setLoginData({ ...login_data, [input]: value });
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const { login, senha, lembrar } = { ...login_data };
        const request = new Request();

        const req_login = request.setRequest('login', 'login', {
            login,
            senha,
            lembrar,
        });

        const result = await request.execute();

        if (result && result[req_login]) {
            window.location.reload();
        } else if (result === undefined) {
            error('Sistema temporariamente indisponível');
        } else {
            error('Usuário e senha inválidos!');
        }
    }

    function handleLembrar() {
        const { lembrar } = login_data;
        setLoginData({ ...login_data, lembrar: !lembrar });
    }

    return (
        <StyledLogin>
            <Form id="login" onSubmit={handleSubmit}>
                <Logo>
                    <Imgs>
                        <img src={logo} alt="logo" />
                    </Imgs>
                    <Versao>{`VERSÃO ${AppData.version}`}</Versao>
                </Logo>

                <InputContainer htmlFor="logar">
                    <Input
                        type="text"
                        name="login"
                        placeholder="E-Mail"
                        required
                        onChange={handleInput}
                        value={login_data.login}
                    />
                </InputContainer>

                <InputContainer htmlFor="senha">
                    <Input
                        type="password"
                        name="senha"
                        placeholder="Senha"
                        required
                        onChange={handleInput}
                        value={login_data.senha}
                    />
                </InputContainer>

                <Remember>
                    <Switch htmlFor="lembrar">
                        <StyledCheckBox
                            type="checkbox"
                            id="lembrar"
                            name="lembrar"
                            value={login_data.lembrar}
                            onClick={handleLembrar}
                        />
                        <Slider />
                    </Switch>
                    <Conectado>MANTENHA-ME CONECTADO</Conectado>
                </Remember>

                <Entrar type="submit" value="ENTRAR" />
            </Form>
        </StyledLogin>
    );
}

export default Login;
