import React, { useRef } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import PropTypes from 'prop-types';

import { error, success } from '../../../../components/alerts';
import Select from '../../../../components/form-components/select';
import Popup from '../../../../components/popup';
import Table from '../../../../components/table';
import Request, { getLimit } from '../../../../utils/Request';
import { pathname2Query } from '../../../../utils/RouterRes';
import { parser } from '../../../../utils/Select';

function Presenca({ open, setOpen }) {
    const tableRef = useRef();

    const match = useRouteMatch();
    const location = useLocation();

    const url_params = pathname2Query(location.pathname, match.url);

    async function getPresencaSituacao(search) {
        const request = new Request();

        const req_presenca_situacao = request.setRequest(
            'presenca_situacoes',
            'listar',
            {
                search,
            }
        );
        const result = await request.execute();

        const { dados } = result[req_presenca_situacao] || [];

        return parser('descricao', 'id', dados);
    }

    async function getVereadores({ page, limit: table_limit }) {
        const request = new Request();

        const limit = getLimit(page, table_limit);

        const req_vereadores = request.setRequest(
            'presencas',
            'listarVereadores',
            {
                limit,
                sessao_id: url_params.id,
            }
        );

        const result = await request.execute();

        if (result && result[req_vereadores]) {
            return result[req_vereadores];
        }
        return {};
    }

    async function handlePresencaChange({
        vereador_id,
        sessao_id,
        presenca_situacao_id,
    }) {
        if (vereador_id && sessao_id && presenca_situacao_id) {
            const request = new Request();
            const req_presenca = request.setRequest('presencas', 'salvar', {
                vereador_id,
                sessao_id,
                presenca_situacao_id,
            });

            const result = await request.execute();

            if (
                result &&
                result[req_presenca] &&
                result[req_presenca] !== false
            ) {
                success('Situação alterada com sucesso!');
                tableRef.current.fireFetchData();
            } else {
                error('Não foi possível alterar a presença do vereador!');
            }
        } else {
            error('Vereador inválido!');
        }
    }

    return (
        <Popup
            height="600px"
            title="Presenças"
            open={open}
            onClose={() => setOpen(false)}
            contentOverflow
        >
            <Table
                headers={[
                    {
                        accessor: 'nome',
                        name: 'Vereador',
                    },
                    {
                        accessor: 'presenca',
                        name: 'Presença',
                        Cell: (cellProps) => {
                            const {
                                original: {
                                    id: vereador_id,
                                    presenca_situacao_id,
                                    presenca_situacao_descricao,
                                },
                            } = cellProps;

                            const selectedValue =
                                presenca_situacao_id &&
                                presenca_situacao_descricao
                                    ? {
                                          label: presenca_situacao_descricao,
                                          value: presenca_situacao_id,
                                      }
                                    : {};

                            return (
                                <Select
                                    name="presenca_select"
                                    type_select="async"
                                    type="select"
                                    placeholder="Presença"
                                    size={1}
                                    value={selectedValue}
                                    defaultOptions
                                    fullWidth
                                    cacheOptions
                                    onChange={async (value) => {
                                        const updated = await handlePresencaChange(
                                            {
                                                vereador_id,
                                                sessao_id: url_params.id,
                                                presenca_situacao_id:
                                                    value.value || null,
                                            }
                                        );

                                        if (updated) {
                                            tableRef.current.fireFetchData();
                                        }
                                    }}
                                    margin="0"
                                    loadOptions={getPresencaSituacao}
                                />
                            );
                        },
                    },
                ]}
                tableRef={tableRef}
                data_function={getVereadores}
            />
        </Popup>
    );
}

Presenca.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};

export default Presenca;
