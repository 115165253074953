import React, { useContext, useEffect } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';

import favicon from '../../assets/img/icone.ico';
import { error } from '../../components/alerts';
import Error from '../../components/error';
import Header, { HeaderRight } from '../../components/header';
import Icon from '../../components/icon';
import Sidebar from '../../components/sidebar';
import { colors } from '../../configs/theme';
import { TAWKTO_TOKEN } from '../../data/constants';
import Comissao from '../../pages/painel/comissao';
import Comissoes from '../../pages/painel/comissoes';
import Home from '../../pages/painel/home';
import Legislatura from '../../pages/painel/legislatura';
import Legislaturas from '../../pages/painel/legislaturas';
import Materia from '../../pages/painel/materia';
import Materias from '../../pages/painel/materias';
import Sessao from '../../pages/painel/sessao';
import Sessoes from '../../pages/painel/sessoes';
import Usuarios from '../../pages/painel/usuarios';
import Vereador from '../../pages/painel/vereador';
import Vereadores from '../../pages/painel/vereadores';
import tawkto from '../../utils/tawk.to';
import { AppContext } from '../app-container';
import PrivateRouter from './router/private-router';
import { AppContent, Container } from './styles';

function Painel() {
    const { client, user, logout } = useContext(AppContext);
    const history = useHistory();

    useEffect(() => {
        if (history.action === 'REPLACE') {
            error('Você não tem permissão para acessar essa página!');
        }
    }, [history.action]);

    useEffect(() => {
        const link =
            document.querySelector("link[rel*='icon']") ||
            document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = favicon;
        document.getElementsByTagName('head')[0].appendChild(link);

        if (process.env.NODE_ENV !== 'development') {
            tawkto(TAWKTO_TOKEN, () => {});
        }
    }, []);

    return (
        <Route>
            <Container border>
                <Sidebar painel="true" />
                <Container direction="column" content="true">
                    <Header painel="true">
                        <HeaderRight
                            instituicao={client.nome}
                            username={user.nome}
                        >
                            <Link to="/painel/usuarios" title="Usuários">
                                <Icon
                                    className="icon-pessoal"
                                    size="20px"
                                    pointer
                                    color={colors.text_gray_dark}
                                />
                            </Link>
                            <div
                                title="Sair"
                                onClick={(e) => logout && logout(e)}
                            >
                                <Icon
                                    className="icon-power"
                                    size="20px"
                                    pointer
                                    color={colors.text_gray_dark}
                                />
                            </div>
                        </HeaderRight>
                    </Header>

                    <AppContent>
                        <Switch>
                            <PrivateRouter
                                publica
                                path="/painel/comissoes/editor"
                                component={Comissao}
                            />
                            <PrivateRouter
                                publica
                                path="/painel/comissoes"
                                component={Comissoes}
                            />
                            <PrivateRouter
                                publica
                                path="/painel/legislaturas/editor"
                                component={Legislatura}
                            />
                            <PrivateRouter
                                publica
                                path="/painel/legislaturas"
                                component={Legislaturas}
                            />
                            <PrivateRouter
                                publica
                                path="/painel/materias/editor"
                                component={Materia}
                            />
                            <PrivateRouter
                                publica
                                path="/painel/materias"
                                component={Materias}
                            />
                            <PrivateRouter
                                publica
                                path="/painel/sessoes/editor"
                                component={Sessao}
                            />
                            <PrivateRouter
                                publica
                                path="/painel/sessoes"
                                component={Sessoes}
                            />
                            <PrivateRouter
                                publica
                                path="/painel/usuarios"
                                component={Usuarios}
                            />
                            <PrivateRouter
                                publica
                                path="/painel/vereadores/editor"
                                component={Vereador}
                            />
                            <PrivateRouter
                                publica
                                path="/painel/vereadores"
                                component={Vereadores}
                            />
                            <PrivateRouter
                                publica
                                path="/painel"
                                component={Home}
                            />
                            <Route component={Error} />
                        </Switch>
                    </AppContent>
                </Container>
            </Container>
        </Route>
    );
}

export default Painel;
