import React from 'react';

import PropTypes from 'prop-types';

import { Row } from '../../../app/global-styles';
import { colors } from '../../../configs/theme';
import Icon from '../../icon';
import ErrorMessage from '../error-message';
import {
    Container,
    IconContainer,
    Label,
    LabelMessage,
    Required,
} from '../styles';
import { StyledMaskedInput } from './styles';

function Mask({
    height,
    background,
    radius,
    width,
    icon,
    type,
    label,
    required,
    relative,
    name,
    id,
    error_message,
    ...props
}) {
    const icon_size = `calc(${height} * 0.4)`;
    const padding_icon = icon ? '40px' : null;
    const border = `2px solid ${colors.white}`;
    const padding = '10px';
    id = id || name || null;

    const container_props = {
        width,
    };

    const input_props = {
        width: '100%',
        id,
        error_message,
        ...props,
    };

    input_props.radius = radius;
    input_props.border = border;
    input_props.background = background;
    input_props.shadow = 'true';

    return (
        <Container {...container_props}>
            <Label htmlFor={id}>
                {label && (
                    <LabelMessage>
                        {label}
                        {required && <Required>*</Required>}
                    </LabelMessage>
                )}
                <Row height={height} relative={relative}>
                    {icon && (
                        <IconContainer padding_icon={padding_icon}>
                            <Icon
                                size={icon_size}
                                color={colors.grey_placeholder}
                                className={icon}
                            />
                        </IconContainer>
                    )}
                    <StyledMaskedInput
                        type={type}
                        padding={padding}
                        padding_icon={padding_icon}
                        {...input_props}
                    />
                    {error_message && (
                        <ErrorMessage error_message={error_message} />
                    )}
                </Row>
            </Label>
        </Container>
    );
}

Mask.propTypes = {
    height: PropTypes.string,
    icon: PropTypes.string,
    background: PropTypes.string,
    radius: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    guide: PropTypes.bool,
    keepCharPositions: PropTypes.bool,
    showMask: PropTypes.bool,
    fontSize: PropTypes.number,
    width: PropTypes.string,
    mask: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    error_message: PropTypes.string,
    relative: PropTypes.bool,
};

Mask.defaultProps = {
    height: '45px',
    radius: '5px',
    fontSize: 20,
    width: '100%',
    icon: null,
    id: null,
    name: null,
    error_message: null,
    relative: true,
    required: false,
    showMask: false,
    keepCharPositions: false,
    guide: false,
    type: 'text',
    background: colors.white,
};

export default Mask;
