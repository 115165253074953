import styled from 'styled-components';

import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

export const Container = styled('nav')`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: flex-end;
    width: 100%;
    background-color: white;
`;

export const Group = styled('div')`
    flex-direction: column;
    display: flex;

    h2 {
        font-size: ${parse(24)};
        font-weight: bold;
        margin-bottom: 6px;
    }

    > nav {
        display: flex;
        align-items: center;

        a {
            text-transform: uppercase;
            font-size: ${parse(11)};
            font-weight: bold;

            &:hover {
                color: ${colors.color_primary};
            }
        }

        span {
            margin: 0 10px;
            height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                font-size: 12px;
                height: 12px;
            }
        }
    }
`;
