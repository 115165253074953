import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { FieldArray, Field, Formik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import { Row } from '../../../app/global-styles';
import { error, info, loading, success } from '../../../components/alerts';
import Button from '../../../components/button';
import ControlledInput from '../../../components/form-components/controlled-input';
import {
    AddButton,
    ContainerVereadores,
} from '../../../components/form-components/repeater/styles';
import { LabelMessage } from '../../../components/form-components/styles';
import Icon from '../../../components/icon';
import PageContainer from '../../../components/page-container';
import { colors } from '../../../configs/theme';
import Request from '../../../utils/Request';
import 'moment/locale/pt-br';
import { pathname2Query } from '../../../utils/RouterRes';
import { parser } from '../../../utils/Select';

moment.locale('pt-br');

const formValues = {
    ano_inicio: '',
    ano_fim: '',
    vereadores: [{ vereador: {}, funcao: {} }],
};

function Legislatura() {
    const [legislatura, setLegislatura] = useState({});
    const [totalVereadores, setTotalVereadores] = useState(0);
    const location = useLocation();
    const match = useRouteMatch();
    const url_params = pathname2Query(location.pathname, match.url);
    const history = useHistory();
    const initialValues =
        legislatura && Object.keys(legislatura).length > 0
            ? legislatura
            : formValues;

    async function getLegislatura(id) {
        const request = new Request();

        const req_legislatura = request.setRequest('legislaturas', 'listar', {
            id,
        });

        const result = await request.execute();

        const { dados } = result[req_legislatura];

        if (dados && dados.id) {
            const legislatura_to_edit = {};

            legislatura_to_edit.id = dados.id;

            if (dados.ano_inicio && dados.ano_inicio !== '') {
                legislatura_to_edit.ano_inicio = moment(
                    dados.ano_inicio,
                    'YYYY'
                ).toDate();
            } else {
                legislatura_to_edit.ano_inicio = '';
            }

            if (dados.ano_fim && dados.ano_fim !== '') {
                legislatura_to_edit.ano_fim = moment(
                    dados.ano_fim,
                    'YYYY'
                ).toDate();
            } else {
                legislatura_to_edit.ano_fim = '';
            }

            if (dados.vereadores.length > 0) {
                const vereadores = [];

                dados.vereadores.forEach((item) => {
                    vereadores.push({
                        vereador: {
                            label: item.nome,
                            value: item.vereador_id,
                        },
                        funcao: {
                            label: item.descricao,
                            value: item.funcao_id,
                        },
                    });
                });

                legislatura_to_edit.vereadores = vereadores;
            } else {
                legislatura_to_edit.vereadores = [{ vereador: {}, funcao: {} }];
            }

            setLegislatura(legislatura_to_edit);
        } else {
            history.push('/painel/legislaturas');
        }
    }

    async function getVereadoresSelect(search, ids) {
        const request = new Request();
        const params_search = {
            ids,
        };

        const req_vereadores = request.setRequest('vereadores', 'listar', {
            search,
            params_search,
        });

        const result = await request.execute();

        const { dados } = result[req_vereadores] || [];

        return parser('nome', 'id', dados);
    }

    async function getFuncoesSelect(search) {
        const request = new Request();

        const req_funcoes = request.setRequest(
            'legislatura_funcoes',
            'listar',
            {
                search,
            }
        );

        const result = await request.execute();

        const { dados } = result[req_funcoes] || [];

        return parser('descricao', 'id', dados);
    }

    async function handleSubmit(values, state) {
        const request = new Request();
        const legislatura_to_save = {};

        if (legislatura.id) {
            legislatura_to_save.id = legislatura.id;
        }

        legislatura_to_save.ano_inicio = moment(values.ano_inicio).format(
            'YYYY'
        );
        legislatura_to_save.ano_fim = moment(values.ano_fim).format('YYYY');

        legislatura_to_save.vereadores = [];
        values.vereadores.forEach((item) => {
            legislatura_to_save.vereadores.push({
                vereador_id: item.vereador.value,
                funcao_id: item.funcao.value,
            });
        });

        const loadToast = loading('Salvando legislatura');

        try {
            const req_legislatura = request.setRequest(
                'legislaturas',
                'salvar',
                {
                    legislatura: legislatura_to_save,
                }
            );

            const result = await request.execute();

            if (result[req_legislatura] === true) {
                loadToast();
                success(
                    `Legislatura ${legislatura_to_save.ano_inicio}/${legislatura_to_save.ano_fim} alterada com sucesso`
                );
                state.setSubmitting(false);
            } else if (Number(result[req_legislatura]) > 0) {
                loadToast();
                success(
                    `Legislatura ${legislatura_to_save.ano_inicio}/${legislatura_to_save.ano_fim} adicionada com sucesso`
                );
                history.push(
                    `/painel/legislaturas/editor/id=${result[req_legislatura]}`
                );
                state.setSubmitting(false);
            } else {
                loadToast();
                error('Não foi possível salvar legislatura!');
                state.setSubmitting(false);
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar legislatura!');
            state.setSubmitting(false);
        }
    }

    async function getVereadores() {
        const request = new Request();
        const req_vereadores = request.setRequest('vereadores', 'listar', {
            campos: 'GROUP_CONCAT(id) as ids',
        });

        const result = await request.execute();

        const { total } = result[req_vereadores] || [];

        setTotalVereadores(total);
    }

    async function getLastLegislatura() {
        const request = new Request();
        const req_vereadores = request.setRequest(
            'legislaturas',
            'ultima_legislatura',
            {}
        );

        const result = await request.execute();

        const dados = result[req_vereadores] || [];
        const vereadores = [];

        if (dados.vereadores && dados.vereadores.length > 0) {
            dados.vereadores.forEach((item) => {
                vereadores.push({
                    vereador: {
                        label: item.nome,
                        value: item.vereador_id,
                    },
                    funcao: {
                        label: item.descricao,
                        value: item.funcao_id,
                    },
                });
            });
        }

        return vereadores;
    }

    useEffect(() => {
        getVereadores().then();
    }, []);

    useEffect(() => {
        if (url_params.id && !isNaN(url_params.id)) {
            getLegislatura(url_params.id).then();
        } else {
            getLastLegislatura().then((res) => {
                if (res.length > 0) {
                    setLegislatura({ vereadores: res });
                } else {
                    setLegislatura({});
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_params.id]);

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row height="auto">
                    <Field
                        component={ControlledInput}
                        name="ano_inicio"
                        placeholder="Ano Inicial"
                        label="Ano Inicial"
                        type="year-picker"
                        required
                        size={2}
                    />
                    <Field
                        component={ControlledInput}
                        name="ano_fim"
                        placeholder="Ano Final"
                        label="Ano Final"
                        type="year-picker"
                        required
                        size={2}
                    />
                </Row>
                <Row height="auto" spaceBetween wrap="wrap">
                    <FieldArray
                        name="vereadores"
                        render={(arrayHelpers) => (
                            <>
                                <Row padding="0 0 0 15px">
                                    <LabelMessage>Vereadores</LabelMessage>
                                </Row>
                                {formState.values.vereadores.map(
                                    (vereador, index) => (
                                        <ContainerVereadores key={index}>
                                            <Field
                                                component={ControlledInput}
                                                name={`vereador.${index}`}
                                                isClearable
                                                margin="0 0 10px 0"
                                                type_select="async"
                                                type="select"
                                                label=""
                                                required
                                                placeholder="Vereador"
                                                defaultOptions
                                                cacheOptions
                                                loadOptions={(search) =>
                                                    getVereadoresSelect(
                                                        search,
                                                        formState.values.vereadores
                                                            .filter(
                                                                ({
                                                                    // eslint-disable-next-line no-shadow
                                                                    vereador,
                                                                }) =>
                                                                    !!vereador.value
                                                            )
                                                            .map(
                                                                ({
                                                                    // eslint-disable-next-line no-shadow
                                                                    vereador,
                                                                }) =>
                                                                    vereador.value
                                                            )
                                                    )
                                                }
                                                onChange={(event) => {
                                                    const vereadores = [
                                                        ...formState.values
                                                            .vereadores,
                                                    ];
                                                    vereadores[index] = {
                                                        ...vereadores[index],
                                                        vereador: event,
                                                    };

                                                    formState.setFieldValue(
                                                        'vereadores',
                                                        vereadores
                                                    );
                                                }}
                                                size={4}
                                                maskedValue={vereador.vereador}
                                                indexError="vereadores"
                                                indexField={index}
                                            />
                                            <Field
                                                component={ControlledInput}
                                                name={`funcao.${index}`}
                                                isClearable
                                                margin="0 0 10px 0"
                                                type_select="async"
                                                type="select"
                                                label=""
                                                required
                                                placeholder="Função"
                                                defaultOptions
                                                cacheOptions
                                                loadOptions={getFuncoesSelect}
                                                onChange={(event) => {
                                                    const vereadores = [
                                                        ...formState.values
                                                            .vereadores,
                                                    ];
                                                    vereadores[index] = {
                                                        ...vereadores[index],
                                                        funcao: event,
                                                    };
                                                    formState.setFieldValue(
                                                        'vereadores',
                                                        vereadores
                                                    );
                                                }}
                                                size={4}
                                                maskedValue={vereador.funcao}
                                                indexError="vereadores"
                                                indexField={index}
                                            />
                                            <Icon
                                                marginBottom="10px"
                                                marginRight="15px"
                                                className="icon-trash"
                                                size="16px"
                                                color={colors.grey_placeholder}
                                                title="Remover campos"
                                                onClick={() => {
                                                    if (index !== 0) {
                                                        arrayHelpers.remove(
                                                            index
                                                        );
                                                    }
                                                }}
                                                hover={colors.white}
                                                kind={
                                                    index === 0
                                                        ? 'disabled-button'
                                                        : 'action-button'
                                                }
                                            />
                                        </ContainerVereadores>
                                    )
                                )}
                                <AddButton
                                    size={8}
                                    onClick={() => {
                                        if (
                                            formState.values.vereadores.length <
                                            totalVereadores
                                        ) {
                                            arrayHelpers.push({
                                                vereador: {},
                                                funcao: {},
                                            });
                                        } else {
                                            info(
                                                'Limite de vereadores atingido!'
                                            );
                                        }
                                    }}
                                >
                                    Adicionar campo
                                </AddButton>
                            </>
                        )}
                    />
                </Row>
                <Row padding="0 15px">
                    {Object.entries(legislatura).length > 0 && (
                        <Button
                            type="reset"
                            kind="cancel"
                            margin="0 10px 0 0"
                            onClick={() => {
                                setLegislatura({});
                                formState.resetForm();
                            }}
                        >
                            Cancelar
                        </Button>
                    )}
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    function renderForm() {
        return (
            <PageContainer title="Legislatura">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        ano_inicio: yup
                            .mixed()
                            .required('Ano Inicial é obrigatório!')
                            .validDate('Data inválida'),
                        ano_fim: yup
                            .mixed()
                            .required('Ano Final é obrigatório!')
                            .validDate('Data inválida'),
                        vereadores: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    funcao: yup
                                        .object()
                                        .validSelect('Função é obrigatória!'),
                                    vereador: yup
                                        .object()
                                        .validSelect('Vereador é obrigatório!'),
                                })
                            )
                            .required('Vereadores são obrigatórios')
                            .min(1, 'Selecione pelo menos um vereador'),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        );
    }

    return (
        <PageContainer padding scroll>
            {renderForm()}
        </PageContainer>
    );
}

export default Legislatura;
