import React from 'react';

import PropTypes from 'prop-types';

import { colors } from '../../configs/theme';
import Icon from '../icon';
import { StyledButton, StyledLink } from './styles';

Button.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    icon: PropTypes.string,
    kind: PropTypes.oneOf([
        'save',
        'cancel',
        'reorder',
        'custom',
        'lista-materia',
        'actions',
    ]),
    width: PropTypes.string,
    height: PropTypes.string,
    to: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    flex: PropTypes.number,
    text: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
};

Button.defaultProps = {
    icon: null,
    kind: 'custom',
    width: null,
    height: null,
    fontSize: null,
    to: null,
    flex: null,
    iconSize: null,
    disabled: false,
    type: 'button',
    padding: null,
    text: null,
    margin: null,
    color: colors.white,
};

function Button({
    children,
    icon,
    kind,
    color,
    iconSize,
    margin,
    to,
    ...props
}) {
    const button_props = {
        kind,
        button_margin: margin,
        to,
        ...props,
    };

    const kind_options = {
        save: 'icon-save',
        cancel: 'icon-close',
        reorder: 'icon-reorder-arrow',
    };

    const icon_props = {
        className: icon || kind_options[kind],
        marginRight: '8px',
        size: iconSize ? `${iconSize}px` : '15px',
    };

    icon = kind_options[kind] || icon;

    if (color) {
        button_props.color = color;
        icon_props.color = color;
    }

    if (kind === 'actions') {
        button_props.color = colors.color_primary;
        icon_props.color = colors.color_primary;
    }

    const Component = to !== null ? StyledLink : StyledButton;

    return (
        <Component {...button_props}>
            {icon && <Icon {...icon_props} />}
            {children}
        </Component>
    );
}

export default Button;
