import React from 'react';

import PropTypes from 'prop-types';

import ItemUploading from '../item-uploading';
import { Container } from './styles';

function ListUploading({ files, zIndex }) {
    return (
        <Container zIndex={zIndex}>
            {files.map((file) => (
                <ItemUploading
                    key={file.uniqueId}
                    error={file.error}
                    name={file.file.name}
                    progress={file.progress}
                    readableSize={file.readableSize}
                    accepted={file.accepted}
                />
            ))}
        </Container>
    );
}

ListUploading.propTypes = {
    files: PropTypes.arrayOf(
        PropTypes.shape({
            uniqueId: PropTypes.string,
            error: PropTypes.string,
            file: PropTypes.shape({
                name: PropTypes.string,
            }),
            readableSize: PropTypes.string,
            accepted: PropTypes.bool,
        })
    ),
    zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ListUploading;
