export const colors = {
    color_primary: '#7977D8',
    color_primary_hover: 'rgba(121, 119, 216, 0.30)',
    color_primary_hover_button: '#6260bb',
    color_primary_hover_selected: '#c7c8f0',
    color_primary_dark: '#242139',
    color_table: '#EFF1F2',
    color_hover_table: '#E8E9FF',
    table_top: '#343154',
    text_gray_default: '#3C434F',
    text_gray_dark: '#7A808A',
    text_gray_one: '#E3E6E9',
    text_gray_two: '#D2D5D8',
    text_gray_three: '#C9CCD0',

    blaze_orange_hover_table: '#ff63004d',
    color_nutmeg_wood_finish_approx: '#692a00',
    color_blaze_orange_approx: '#ff6300',
    color_trinidad_approx: '#de5600',
    color_international_orange_approx: '#ff5a00',
    blaze_orange: '#ff6600',
    color_tenn_approx: '#d05b00',
    color_orange_ffe: '#ffe4d0',
    green: 'green',
    color_green_2: '#297a41',
    grey_radio: '#c3c3c3',
    grey_chumbo_icons: '#828282',
    grey_border_radio: '#d5d5d5',
    color_apple_approx: '#44c433',
    color_green_3: '#3aad5c',
    color_green_5: '#54c676',
    color_green_a: '#a1dfb3',
    red: 'red',
    color_red_aa: '#aa0027',
    color_red_f0: '#ff003b',
    color_scarlet_approx: '#ff2300',
    color_red_f4: '#ff446f',
    color_red_fa: '#ffaabe',
    color_red_cc: '#cc002f',
    maroon: '#800000',
    blue: ' blue',
    navy_blue: '#000080',
    olive: ' olive',
    color_amber_approx: '#ffc100',

    green_repeater: '#D4F5DE',

    white: 'white',
    color_celeste_approx: '#ccc',
    color_pink_swan_approx: '#bbb',
    primary_green: '#39BA69',
    secondary_green: '#65ac63',
    green_hover: '#d2e4b7',
    primary_blue: '#01B1CB',
    blue_disabled: '#699096',
    primary_blue_hover: 'rgba(28,168,189,0.31)',
    primary_blue_hover_button: '#31BFD3',
    primary_blue_hover_selected: '#D7ECF0',
    secondary_blue: '#283241',
    primary_grey: '#E3E6E9',
    grey_text: '#A8ACB1',
    grey_placeholder: '#7A808A',
    grey_input_cidadao: '#E1E4E9',
    grey_placeholder_select: 'rgba(122,128,138,.53)',
    grey_header: '#dddddd',
    grey_chumbo: '#283241',
    grey_chumbo_table: '#2C313B',
    grey_chumbo_pagination: '#232A2E',
    grey_lines_table: '#EFF1F2',
    grey_pagination: '#D2D5D8',
    black_table: '#3C434F',
    red_required: '#FF0000',
    red_error_message: '#FD4747',
    red_cancel: '#FE534F',
    background_buttons: 'linear-gradient(180deg, #FFFFFF 0%, #E3E6E9 100%)',
    red_cancel_hover: '#fe7872',
    red_cancel_hover_light: '#e89692',
};

export const fonts = {
    font_principal: 'Arial',
    font_secundaria: 'serif',
};

export const sizes = {
    sidebar_width: '180px',
    font_size_base: 16,
};
