import React from 'react';
import ReactSlider from 'react-slider';

import styled from 'styled-components';

import { colors } from '../../../configs/theme';

export const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 25px;
`;

const StyledThumb = styled('div')`
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: ${colors.secondary_blue};
    color: ${colors.white};
    border-radius: 50%;
    cursor: grab;
`;

export const Thumb = (props, state) => (
    <StyledThumb {...props}>{state.valueNow}</StyledThumb>
);

const StyledTrack = styled('div')`
    top: 0;
    bottom: 0;
    background: ${(props) =>
        props.index === 2
            ? colors.red_required
            : props.index === 1
            ? colors.grey_placeholder_select
            : colors.primary_green};
    border-radius: 999px;
`;

export const Track = (props, state) => (
    <StyledTrack {...props} index={state.index} />
);
