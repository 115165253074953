import ReactTable from 'react-table-6';

import styled, { css } from 'styled-components';

import 'react-table-6/react-table.css';
import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

const linesBackground = (viewIndex) => {
    if (viewIndex % 2 === 0) {
        return css`
            background-color: ${colors.white};

            &:nth-child(even) {
                background-color: ${colors.grey_lines_table};
            }
        `;
    }
};

export const HiddenOptions = styled('div')`
    display: flex;
    opacity: 0;
    transition: 0.4s;
`;

export const StyledTable = styled(ReactTable)`
    //height: 100%;
    position: relative;
    //width: 100%;

    border: unset !important;
    box-shadow: unset !important;

    * {
        //border: unset !important;
        //box-shadow: unset !important;
    }

    .pagination-bottom {
        margin-top: 10px;
        ${(props) =>
            props.zIndex &&
            css`
                z-index: ${props.zIndex};
            `}
    }

    .rt-table {
        max-height: ${(props) =>
            props.scrollable ? 'calc(100% - 53px)' : '100%'};
        overflow: unset !important;

        .rt-thead {
            width: 100%;
            height: 47px;
            text-align: left;
            font-family: Arial, serif;
            color: ${colors.white};
            font-size: ${parse(14)};
            font-weight: bold;
            border-radius: 5px 5px 0 0;
            background: ${colors.table_top};
            ${(props) =>
                props.hideHeader &&
                css`
                    display: none;
                `}

            .rt-tr {
                display: flex;
                .rt-th {
                    padding: 0;
                    border: unset;

                    div {
                        text-align: left;
                        padding: 15px 0 15px 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &:first-child {
                        div {
                            padding-left: 17px;
                        }
                    }

                    &:last-child {
                        div {
                            padding-right: 20px;
                        }
                    }
                }
            }
        }

        .rt-tbody {
            max-height: 100%;
            display: flex;
            flex-direction: column;
            border-radius: 0 0 5px 5px;
            .rt-tr-group {
                border: unset;
                background: ${colors.grey_lines_table};
                color: ${colors.grey_chumbo_table};

                &:hover {
                    ${HiddenOptions} {
                        opacity: 1;
                    }
                }

                &:nth-child(even) {
                    background-color: ${colors.white};
                }

                ${(props) => props.nesting && linesBackground(props.viewIndex)}

                .rt-tr {
                    border-left: 3px solid transparent;
                    display: flex;

                    &:hover:not(.-padRow) {
                        cursor: pointer;
                        border-left-color: ${colors.color_primary};
                        background-color: ${colors.color_hover_table};
                    }

                    .rt-td {
                        border: unset;
                        text-align: left;
                        padding: 14px 0 14px 20px;
                        white-space: normal;
                        text-overflow: unset;
                        display: flex;
                        align-items: center;
                        overflow: unset !important;

                        &:first-child {
                            padding-left: 17px;
                            ${(props) =>
                                props.padding_children &&
                                css`
                                    padding-left: calc(
                                        17px * ${props.padding_children}
                                    );
                                `}
                        }

                        &:last-child {
                            padding-right: 20px;
                        }
                    }
                }
            }
        }
        ${(props) =>
            props.zIndex &&
            css`
                z-index: ${props.zIndex};
            `}
    }

    ${(props) =>
        props.hide &&
        css`
            display: none !important;
        `}
`;
