import React, { useRef, useState, useEffect } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router';

import { Field, Formik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import { Row } from '../../../app/global-styles';
import { loading, success, error } from '../../../components/alerts';
import Button from '../../../components/button';
import confirmAlert from '../../../components/confirm-alert';
import ControlledInput from '../../../components/form-components/controlled-input';
import Select from '../../../components/form-components/select';
import Icon from '../../../components/icon';
import PageContainer from '../../../components/page-container';
import ReorderPopup from '../../../components/reorder-popup';
import Resizer from '../../../components/resizer';
import TableFileUploader from '../../../components/table-file-uploader';
import { colors } from '../../../configs/theme';
import Request, { getLimit } from '../../../utils/Request';
import { pathname2Query } from '../../../utils/RouterRes';
import { parser } from '../../../utils/Select';
import Pautas from './pautas';
import Presenca from './presenca';

function Sessao() {
    const [sessao, setSessao] = useState({});
    const [isPopupPautasOpen, setIsPopupPautasOpen] = useState(false);
    const [isPopupPresencaOpen, setIsPopupPresencaOpen] = useState(false);
    const [firstUploadFiles, setFirstUploadFiles] = useState([]);
    const tableRef = useRef();
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    const url_params = pathname2Query(location.pathname, match.url);

    const initialValues =
        sessao && Object.keys(sessao).length > 0
            ? sessao
            : {
                  tipo_sessao: {},
                  ano: '',
                  data: '',
                  numero: '',
              };

    async function getSessao(id) {
        const request = new Request();

        const req_sessao = request.setRequest('sessoes', 'listar', { id });

        const result = await request.execute();

        const { dados } = result[req_sessao];

        if (dados && dados.id) {
            const sessao_to_edit = {};
            sessao_to_edit.numero = dados.numero;
            sessao_to_edit.protocolo = dados.protocolo;
            sessao_to_edit.ementa = dados.ementa;
            sessao_to_edit.id = dados.id;

            if (dados.data && dados.data !== '') {
                sessao_to_edit.data = moment(dados.data, 'YYYY-MM-DD').toDate();
            } else {
                sessao_to_edit.data = '';
            }

            if (dados.ano && dados.ano !== '') {
                sessao_to_edit.ano = moment(dados.ano, 'YYYY').toDate();
            } else {
                sessao_to_edit.ano = '';
            }

            if (dados.tipo_id && !isNaN(dados.tipo_id)) {
                sessao_to_edit.tipo_sessao = {
                    value: dados.tipo_id,
                    label: dados.sessao_tipo_descricao,
                };
            } else {
                sessao_to_edit.tipo_sessao = {};
            }

            setSessao(sessao_to_edit);
        } else {
            history.push('/painel/materias');
        }
    }

    useEffect(() => {
        if (url_params.id && !isNaN(url_params.id)) {
            getSessao(url_params.id).then();
        } else {
            setSessao({});
            tableRef.current.fireFetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_params.id]);

    async function getSessaoTipoSelect(search) {
        const request = new Request();

        const req_sessao_tipos = request.setRequest('sessao_tipos', 'listar', {
            search,
        });
        const result = await request.execute();

        const { dados } = result[req_sessao_tipos] || [];

        return parser('descricao', 'id', dados);
    }

    async function getSessaoTipoAnexos(search) {
        const request = new Request();

        const req_tipos = request.setRequest('sessao_anexos_tipos', 'listar', {
            search,
        });
        const result = await request.execute();

        const { dados } = result[req_tipos] || [];

        return parser('descricao', 'id', dados);
    }

    async function setFileToSessao(file) {
        const request = new Request();

        const anexo = {
            midia_id: file.id,
            sessao_id: url_params.id,
        };

        try {
            const req_file_sessao = request.setRequest(
                'sessao_anexos',
                'salvar',
                { anexo }
            );

            const result = await request.execute();

            if (result && result[req_file_sessao]) {
                success(`Anexo "${file.titulo}" salvo com sucesso!`);
                tableRef.current.fireFetchData();
            } else {
                error('Falha ao realizar upload do anexo!');
            }
        } catch (e) {
            error('Sistema indispoinível.');
        }
    }

    async function handleReorder(lista) {
        const request = new Request();

        const req_reordenar = request.setRequest('sessao_anexos', 'reordenar', {
            sessao_id: url_params.id,
            lista,
        });

        const result = await request.execute();

        if (result && result[req_reordenar]) {
            tableRef.current.fireFetchData();
            return result && result[req_reordenar];
        }

        return false;
    }

    async function handleSubmit(values, formState) {
        const sessao_to_save = {};
        const loadToast = loading('Salvando matéria');

        sessao_to_save.numero = values.numero;

        if (url_params.id) {
            sessao_to_save.id = url_params.id;
        }

        if (values.tipo_sessao.value) {
            sessao_to_save.tipo_id = values.tipo_sessao.value;
        }

        if (values.ano) {
            sessao_to_save.ano = moment(values.ano).format('YYYY');
        }

        if (values.data) {
            sessao_to_save.data = moment(values.data).format('YYYY-MM-DD');
        }

        try {
            const request = new Request();

            const req_sessao = request.setRequest('sessoes', 'salvar', {
                sessao: sessao_to_save,
                midias: firstUploadFiles,
            });

            const result = await request.execute();

            if (result[req_sessao] === true) {
                loadToast();
                success(`Sessão alterada com sucesso`);
                formState.setSubmitting(false);
            } else if (Number(result[req_sessao]) > 0) {
                loadToast();
                success(`Sessão adicionada com sucesso`);
                history.push(`/painel/sessoes/editor/id=${result[req_sessao]}`);
                formState.setSubmitting(false);
            } else {
                loadToast();
                error('Não foi possível salvar sessão!');
                formState.setSubmitting(false);
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar sessão!');
            formState.setSubmitting(false);
        }
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="numero"
                        type="text"
                        required
                        label="Número"
                        placeholder="Número"
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        name="ano"
                        type="year-picker"
                        required
                        label="Ano"
                        placeholder="Ano"
                        size={4}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="tipo_sessao"
                        isClearable
                        type_select="async"
                        type="select"
                        label="Tipo de sessão"
                        placeholder="Tipo de sessão"
                        size={4}
                        required
                        defaultOptions
                        cacheOptions
                        loadOptions={getSessaoTipoSelect}
                    />
                    <Field
                        component={ControlledInput}
                        name="data"
                        type="date-picker"
                        required
                        label="Data de Realização"
                        placeholder="Data de Realização"
                        size={4}
                    />
                </Row>
                <Row contentEnd padding="0 15px">
                    {url_params.id && (
                        <>
                            <Button
                                type="button"
                                disabled={formState.isSubmitting}
                                onClick={() => setIsPopupPautasOpen(true)}
                                margin="0 10px 0 0"
                            >
                                Pautas
                            </Button>
                            <Button
                                type="button"
                                disabled={formState.isSubmitting}
                                onClick={() => setIsPopupPresencaOpen(true)}
                                margin="0 10px 0 0"
                            >
                                Presenças
                            </Button>
                        </>
                    )}
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    async function getAnexos({ page, limit: table_limit } = {}) {
        if (url_params.id) {
            const request = new Request();

            const limit = table_limit ? getLimit(page, table_limit) : null;

            const params_search = {
                sessao_id: url_params.id,
            };

            const req_sessao_anexos = request.setRequest(
                'sessao_anexos',
                'listar',
                { limit, params_search }
            );
            const result = await request.execute();

            if (result && result[req_sessao_anexos]) {
                return result[req_sessao_anexos];
            }
            return {};
        }
        return {};
    }

    async function desativarAnexo({ original }) {
        if (original && original.midia_id) {
            const desativar = async () => {
                const request = new Request();

                const { midia_id, sessao_id } = original;

                const req_desativar = request.setRequest(
                    'sessao_anexos',
                    'desativar',
                    { midia_id, sessao_id }
                );
                const result = await request.execute();

                if (result[req_desativar] === true) {
                    success('Anexo excluído com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir anexo!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir anexo?',
                subtitle: `Ao confirmar o anexo ${original.titulo} será excluído!`,
                onConfirm() {
                    desativar(original).then();
                },
            });
        } else {
            error('Anexo inválida!');
        }
    }

    async function updateTipoAnexo(anexo) {
        const request = new Request();

        const req_update_tipo = request.setRequest(
            'sessao_anexos',
            'updateTipo',
            { ...anexo }
        );

        const result = await request.execute();

        return (
            result &&
            result[req_update_tipo] &&
            result[req_update_tipo] === true
        );
    }

    const tableProps = {
        headers: [
            {
                name: 'Tipo',
                accessor: 'tipo_id',
                Cell: (props) => {
                    const {
                        original: {
                            sessao_id,
                            midia_id,
                            tipo_id,
                            tipo_anexo_descricao,
                        },
                    } = props;

                    const selectedValue =
                        tipo_id && tipo_anexo_descricao
                            ? {
                                  label: tipo_anexo_descricao,
                                  value: tipo_id,
                              }
                            : {};

                    return (
                        <Select
                            name="tipo_select"
                            isClearable
                            type_select="async"
                            type="select"
                            placeholder="Tipo de anexo"
                            size={1}
                            value={selectedValue}
                            defaultOptions
                            fullWidth
                            cacheOptions
                            onChange={async (value) => {
                                const updated = await updateTipoAnexo({
                                    midia_id,
                                    sessao_id,
                                    tipo_id: value.value || null,
                                });

                                if (updated) {
                                    tableRef.current.fireFetchData();
                                }
                            }}
                            margin="0"
                            loadOptions={getSessaoTipoAnexos}
                        />
                    );
                },
            },
            {
                name: 'Mídia',
                accessor: 'titulo',
            },
            {
                name: 'Data de publicação',
                accessor: 'data',
                Cell: (props) => (
                    <>
                        {props.value &&
                            props.value
                                .split(' ')
                                .map((date) =>
                                    date
                                        .split('-')
                                        .reverse()
                                        .join('/')
                                )
                                .join(' ')}
                    </>
                ),
            },
        ],
        tableRef,
        data_function: getAnexos,
        options: (table_props) => (
            <Icon
                hidden
                size="16px"
                hover={colors.red_error_message}
                color={colors.black_table}
                className="icon-trash"
                onClick={() => desativarAnexo(table_props)}
            />
        ),
    };

    if (firstUploadFiles.length > 0) {
        tableProps.data = firstUploadFiles;
    }

    return (
        <Resizer left={50} right={50} minLeft={500} minRight={500}>
            <PageContainer>
                <TableFileUploader
                    tableProps={tableProps}
                    onFirstUpload={(uploaded_file) => {
                        if (!url_params.id) {
                            setFirstUploadFiles((olderFiles) => [
                                ...olderFiles,
                                uploaded_file,
                            ]);
                        } else {
                            setFileToSessao(uploaded_file);
                        }
                    }}
                    buttons={({ canHover, openFileDialog }) => {
                        return (
                            <Row>
                                <Button
                                    position="relative"
                                    margin="0 0 5px 0"
                                    icon="icon-upload"
                                    iconSize={16}
                                    zIndex={canHover ? 2 : 0}
                                    onClick={openFileDialog}
                                >
                                    Anexo
                                </Button>
                                {url_params.id && (
                                    <ReorderPopup
                                        tableRef={tableRef}
                                        getData={getAnexos}
                                        handleReorder={handleReorder}
                                        dataInfo={{
                                            value: 'midia_id',
                                            label: 'titulo',
                                        }}
                                    />
                                )}
                            </Row>
                        );
                    }}
                />
            </PageContainer>
            <PageContainer title="Sessão">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        tipo_sessao: yup
                            .mixed()
                            .validSelect('Tipo de sessão é obrigatório!'),
                        ano: yup
                            .mixed()
                            .validDate('Ano inválido!')
                            .required('Ano é obrigatório!'),
                        data: yup.mixed().validDate('Data inválida!'),
                        numero: yup.string().required('Número é obrigatório!'),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
                <Pautas
                    open={isPopupPautasOpen}
                    setOpen={setIsPopupPautasOpen}
                />
                <Presenca
                    open={isPopupPresencaOpen}
                    setOpen={setIsPopupPresencaOpen}
                />
            </PageContainer>
        </Resizer>
    );
}

export default Sessao;
