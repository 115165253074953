import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';

import filesize from 'filesize';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

import { Row } from '../../app/global-styles';
import { useForceUpdate } from '../../hooks/force-update';
import { uploadFile } from '../../utils/api';
import { error } from '../alerts';
import Table from '../table';
import InputUpload from './input-upload';
import ListUploading from './list-uploading';
import { Container, ContainerFileUpload } from './styles';

function TableFileUploader({
    onFirstUpload,
    buttons,
    tableProps,
    drop_message,
    forbidden_message,
    upload_message,
    ...props
}) {
    const forceUpdate = useForceUpdate();
    const [pendingFiles, setPendingFiles] = useState([]);
    const uploaderRef = useRef(0);
    const [hoverTable, setHoverTable] = useState(false);

    useEffect(() => {
        if (pendingFiles.length > 0 && uploaderRef.current === 0) {
            uploaderRef.current = 1;
            pendingFiles.forEach((file) => {
                if (file.accepted && !file.uploading) {
                    upload(file);
                } else if (!file.accepted) {
                    setTimeout(() => {
                        removeFile(file.uniqueId);
                    }, 10000);
                }
            });
        }
    }, [pendingFiles]);

    function formatFileInfo(acceptedFiles, rejectedFiles) {
        const dropped_files = [];
        const formatInfo = (file, accepted) => ({
            file,
            uniqueId: uniqueId(),
            accepted,
            progress: 0,
            uploading: false,
            error: accepted ? null : 'Arquivo não permitido!',
            readableSize: filesize(file.size),
        });

        acceptedFiles.forEach((file) => {
            dropped_files.push(formatInfo(file, true));
        });
        rejectedFiles.forEach((file) => {
            dropped_files.push(formatInfo(file, false));
        });

        return dropped_files;
    }

    function updateFile(uniqueId, data) {
        setPendingFiles((prevPendingFiles) =>
            prevPendingFiles.map((uploadedFile) => {
                return uniqueId === uploadedFile.uniqueId
                    ? { ...uploadedFile, ...data }
                    : uploadedFile;
            })
        );
    }

    function removeFile(uniqueId) {
        setPendingFiles((prevFiles) =>
            prevFiles.filter((pending) => uniqueId !== pending.uniqueId)
        );
    }

    async function upload(dropped_file) {
        const uploaded = await uploadFile(
            dropped_file.file,
            (progressUpload) => {
                updateFile(dropped_file.uniqueId, {
                    progress: progressUpload,
                    uploading: true,
                });
            }
        );

        if (uploaded.data && uploaded.data.id && uploaded.data.id > 0) {
            setTimeout(() => {
                removeFile(dropped_file.uniqueId);
            }, 2000);
            if (onFirstUpload) {
                onFirstUpload(uploaded.data);
            }
        } else {
            setPendingFiles((prevFiles) =>
                prevFiles.map((pending) =>
                    dropped_file.uniqueId === pending.uniqueId
                        ? {
                              ...pending,
                              error: 'Erro ao subir arquivo!',
                          }
                        : pending
                )
            );
            setTimeout(() => {
                removeFile(dropped_file.uniqueId);
            }, 10000);
            error(
                `Não foi possível fazer upload do arquivo ${dropped_file.file.name}`
            );
        }
    }

    const onDrop = useCallback(
        (acceptedFiles, rejectedFiles) => {
            const dropped_files = formatFileInfo(acceptedFiles, rejectedFiles);
            uploaderRef.current = 0;
            setPendingFiles([...pendingFiles, ...dropped_files]);
        },
        [pendingFiles]
    );

    const alreadyHaveUploads = !!(
        tableProps.tableRef.current &&
        tableProps.tableRef.current.props.data.length === 0
    );

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        open,
    } = useDropzone({ onDrop, ...props, noClick: !alreadyHaveUploads });

    return (
        <>
            <Container
                onMouseEnter={() => setHoverTable(true)}
                onMouseLeave={() => setHoverTable(false)}
            >
                {!alreadyHaveUploads && buttons && (
                    <Row relative zIndex={hoverTable ? 2 : 0}>
                        {buttons({
                            canHover: hoverTable && !isDragActive,
                            openFileDialog: open,
                        })}
                    </Row>
                )}
                {pendingFiles.length > 0 && (
                    <ListUploading
                        zIndex={!hoverTable && isDragActive ? 0 : 2}
                        files={pendingFiles}
                    />
                )}
                <ContainerFileUpload
                    {...getRootProps({
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                    })}
                    zIndex={!isDragActive && hoverTable ? 0 : 1}
                >
                    <input {...getInputProps()} />
                    {(isDragActive || alreadyHaveUploads) && (
                        <InputUpload
                            isDragAccept={isDragAccept}
                            isDragReject={isDragReject}
                            isDragActive={isDragActive}
                            drop_message={drop_message}
                            forbidden_message={forbidden_message}
                            upload_message={upload_message}
                        />
                    )}
                </ContainerFileUpload>
                <Table
                    zIndex={hoverTable && !isDragActive ? 1 : 0}
                    {...tableProps}
                    hide={alreadyHaveUploads}
                    update_callback={forceUpdate}
                />
            </Container>
        </>
    );
}

TableFileUploader.propTypes = {
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    disabled: PropTypes.bool,
    file: PropTypes.object,
    isUploading: PropTypes.bool,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    multiple: PropTypes.bool,
    noClick: PropTypes.bool,
    drop_message: PropTypes.string,
    forbidden_message: PropTypes.string,
    upload_message: PropTypes.string,
    noDrag: PropTypes.bool,
    noKeyboard: PropTypes.bool,
    onDeleteCb: PropTypes.func,
    buttons: PropTypes.func,
    onDragEnter: PropTypes.func,
    loadData: PropTypes.func,
    onDragLeave: PropTypes.func,
    onDragOver: PropTypes.func,
    tableProps: PropTypes.shape({
        headers: PropTypes.array.isRequired,
        data_function: PropTypes.func,
        submenuOption: PropTypes.func,
        clickHandler: PropTypes.func,
        onCheckboxChange: PropTypes.func,
        subComponent: PropTypes.string,
        noDataText: PropTypes.string,
        checkbox: PropTypes.bool,
        scrollable: PropTypes.bool,
        subComponentHeaders: PropTypes.array,
        pageSizeOptions: PropTypes.array,
        subComponentOptions: PropTypes.func,
        tableRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.any }),
        ]),
        defaultPageSize: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        options: (props, propName, componentName) => {
            if (props[propName] !== null && props.checkbox === true) {
                return new Error(
                    `${propName} can't exists when prop checkbox is defined`
                );
            }

            return null;
        },
    }),
    onFirstUpload: PropTypes.func,
    onDropAccepted: PropTypes.func,
    onDropRejected: PropTypes.func,
    onFileDialogCancel: PropTypes.func,
    preventDropOnDocument: PropTypes.bool,
    progress: PropTypes.number,
};

TableFileUploader.defaultProps = {
    accept: [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/zip',
        'application/x-rar-compressed',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/rtf',
        'text/csv',
        'application/vnd.oasis.opendocument.text',
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/vnd.oasis.opendocument.formula',
    ],
    disabled: false,
    maxSize: 200000000,
    minSize: 1000,
    multiple: true,
    noClick: false,
    noDrag: false,
    onFirstUpload: null,
    buttons: null,
    loadData: null,
    noKeyboard: false,
    preventDropOnDocument: true,
    progress: 0,
    drop_message: 'Solte o arquivo aqui!',
    forbidden_message: 'Arquivo não permitido!',
    upload_message: 'Clique ou arraste para adicionar um arquivo!',
};

export default TableFileUploader;
