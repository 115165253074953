import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { FieldArray, Field, Formik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import { Row } from '../../../app/global-styles';
import { error, info, loading, success } from '../../../components/alerts';
import Button from '../../../components/button';
import ControlledInput from '../../../components/form-components/controlled-input';
import {
    AddButton,
    ContainerVereadores,
} from '../../../components/form-components/repeater/styles';
import { LabelMessage } from '../../../components/form-components/styles';
import Icon from '../../../components/icon';
import PageContainer from '../../../components/page-container';
import { colors } from '../../../configs/theme';
import Request from '../../../utils/Request';
import 'moment/locale/pt-br';
import { pathname2Query } from '../../../utils/RouterRes';
import { parser } from '../../../utils/Select';

moment.locale('pt-br');

const formValues = {
    nome: '',
    sigla: '',
    data_criacao: '',
    ativo: {},
    competencia: '',
    vereadores: [{ vereador: {}, funcao: {} }],
};

function Comissao() {
    const [comissao, setComissao] = useState({});
    const [totalVereadores, setTotalVereadores] = useState(0);
    const location = useLocation();
    const match = useRouteMatch();
    const url_params = pathname2Query(location.pathname, match.url);
    const history = useHistory();
    const initialValues =
        comissao && Object.keys(comissao).length > 0 ? comissao : formValues;

    async function getComissao(id) {
        const request = new Request();

        const req_comissao = request.setRequest('comissoes', 'listar', {
            id,
        });

        const result = await request.execute();

        const { dados } = result[req_comissao];

        if (dados && dados.id) {
            const comissao_to_edit = {};

            comissao_to_edit.id = dados.id;

            comissao_to_edit.nome = dados.nome;
            comissao_to_edit.sigla = dados.sigla;

            if (dados.data_criacao && dados.data_criacao !== '') {
                comissao_to_edit.data_criacao = moment(
                    dados.data_criacao,
                    'YYYY-MM-DD'
                ).toDate();
            } else {
                comissao_to_edit.data_criacao = '';
            }

            if (dados.ativo && !isNaN(dados.ativo)) {
                comissao_to_edit.ativo = {
                    value: dados.ativo,
                    label: dados.ativo === '0' ? 'Não' : 'Sim',
                };
            } else {
                comissao_to_edit.ativo = {};
            }

            comissao_to_edit.competencia = dados.competencia;

            if (dados.vereadores.length > 0) {
                const vereadores = [];

                dados.vereadores.forEach((item) => {
                    vereadores.push({
                        vereador: {
                            label: item.nome,
                            value: item.vereador_id,
                        },
                        funcao: {
                            label: item.descricao,
                            value: item.funcao_id,
                        },
                    });
                });

                comissao_to_edit.vereadores = vereadores;
            } else {
                comissao_to_edit.vereadores = [{ vereador: {}, funcao: {} }];
            }

            setComissao(comissao_to_edit);
        } else {
            history.push('/painel/legislaturas');
        }
    }

    async function getVereadoresSelect(search, ids) {
        const request = new Request();
        const params_search = {
            ids,
        };

        const req_vereadores = request.setRequest('vereadores', 'listar', {
            search,
            params_search,
        });

        const result = await request.execute();

        const { dados } = result[req_vereadores] || [];

        return parser('nome', 'id', dados);
    }

    async function getFuncoesSelect(search) {
        const request = new Request();

        const req_funcoes = request.setRequest('comissao_funcoes', 'listar', {
            search,
        });

        const result = await request.execute();

        const { dados } = result[req_funcoes] || [];

        return parser('descricao', 'id', dados);
    }

    async function getBasicSelect() {
        const dados = [
            { id: '0', descricao: 'Não' },
            { id: '1', descricao: 'Sim' },
        ];
        return parser('descricao', 'id', dados);
    }

    async function handleSubmit(values, state) {
        const request = new Request();
        const comissao_to_save = {};

        if (comissao.id) {
            comissao_to_save.id = comissao.id;
        }

        comissao_to_save.nome = values.nome;
        comissao_to_save.sigla = values.sigla;

        comissao_to_save.data_criacao = moment(values.data_criacao).format(
            'YYYY-MM-DD'
        );

        if (values.ativo.value) {
            comissao_to_save.ativo = values.ativo.value;
        }

        comissao_to_save.competencia = values.competencia;

        comissao_to_save.vereadores = [];
        values.vereadores.forEach((item) => {
            comissao_to_save.vereadores.push({
                vereador_id: item.vereador.value,
                funcao_id: item.funcao.value,
            });
        });

        const loadToast = loading('Salvando comissão');

        try {
            const req_comissao = request.setRequest('comissoes', 'salvar', {
                comissao: comissao_to_save,
            });

            const result = await request.execute();

            if (result[req_comissao] === true) {
                loadToast();
                success(`${comissao_to_save.nome} alterada com sucesso`);
                state.setSubmitting(false);
            } else if (Number(result[req_comissao]) > 0) {
                loadToast();
                success(`${comissao_to_save.nome} adicionada com sucesso`);
                history.push(
                    `/painel/comissoes/editor/id=${result[req_comissao]}`
                );
                state.setSubmitting(false);
            } else {
                loadToast();
                error('Não foi possível salvar comissão!');
                state.setSubmitting(false);
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar comissão!');
            state.setSubmitting(false);
        }
    }

    async function getVereadores() {
        const request = new Request();
        const req_vereadores = request.setRequest('vereadores', 'listar', {
            campos: 'GROUP_CONCAT(id) as ids',
        });

        const result = await request.execute();

        const { total } = result[req_vereadores] || [];

        setTotalVereadores(total);
    }

    useEffect(() => {
        getVereadores().then();
    }, []);

    useEffect(() => {
        if (url_params.id && !isNaN(url_params.id)) {
            getComissao(url_params.id).then();
        } else {
            setComissao({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_params.id]);

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row height="auto">
                    <Field
                        component={ControlledInput}
                        name="nome"
                        placeholder="Nome"
                        label="Nome"
                        maxLength={255}
                        type="text"
                        required
                        size={8}
                    />
                </Row>
                <Row>
                    <Field
                        component={ControlledInput}
                        name="sigla"
                        placeholder="Sigla"
                        label="Sigla"
                        maxLength={20}
                        type="text"
                        size={2}
                    />
                    <Field
                        component={ControlledInput}
                        name="data_criacao"
                        placeholder="Data de Criação"
                        label="Data de Criação"
                        type="date-picker"
                        required
                        size={3}
                    />
                    <Field
                        component={ControlledInput}
                        name="ativo"
                        isClearable
                        required
                        type_select="async"
                        type="select"
                        label="Ativo"
                        placeholder="Selecione"
                        size={3}
                        defaultOptions
                        cacheOptions
                        loadOptions={getBasicSelect}
                    />
                </Row>
                <Row>
                    <Field
                        component={ControlledInput}
                        name="competencia"
                        label="Competência"
                        type="editor"
                    />
                </Row>
                <Row height="auto" spaceBetween wrap="wrap">
                    <FieldArray
                        name="vereadores"
                        render={(arrayHelpers) => (
                            <>
                                <Row padding="0 0 0 15px">
                                    <LabelMessage>Vereadores</LabelMessage>
                                </Row>
                                {formState.values.vereadores.map(
                                    (vereador, index) => (
                                        <ContainerVereadores key={index}>
                                            <Field
                                                component={ControlledInput}
                                                name={`vereador.${index}`}
                                                isClearable
                                                margin="0 0 10px 0"
                                                type_select="async"
                                                type="select"
                                                label=""
                                                required
                                                placeholder="Vereador"
                                                defaultOptions
                                                cacheOptions
                                                menuPlacement="top"
                                                loadOptions={(search) =>
                                                    getVereadoresSelect(
                                                        search,
                                                        formState.values.vereadores
                                                            .filter(
                                                                ({
                                                                    // eslint-disable-next-line no-shadow
                                                                    vereador,
                                                                }) =>
                                                                    !!vereador.value
                                                            )
                                                            .map(
                                                                ({
                                                                    // eslint-disable-next-line no-shadow
                                                                    vereador,
                                                                }) =>
                                                                    vereador.value
                                                            )
                                                    )
                                                }
                                                onChange={(event) => {
                                                    const vereadores = [
                                                        ...formState.values
                                                            .vereadores,
                                                    ];
                                                    vereadores[index] = {
                                                        ...vereadores[index],
                                                        vereador: event,
                                                    };

                                                    formState.setFieldValue(
                                                        'vereadores',
                                                        vereadores
                                                    );
                                                }}
                                                size={4}
                                                maskedValue={vereador.vereador}
                                                indexError="vereadores"
                                                indexField={index}
                                            />
                                            <Field
                                                component={ControlledInput}
                                                name={`funcao.${index}`}
                                                isClearable
                                                margin="0 0 10px 0"
                                                type_select="async"
                                                type="select"
                                                label=""
                                                required
                                                placeholder="Função"
                                                defaultOptions
                                                cacheOptions
                                                loadOptions={getFuncoesSelect}
                                                onChange={(event) => {
                                                    const vereadores = [
                                                        ...formState.values
                                                            .vereadores,
                                                    ];
                                                    vereadores[index] = {
                                                        ...vereadores[index],
                                                        funcao: event,
                                                    };
                                                    formState.setFieldValue(
                                                        'vereadores',
                                                        vereadores
                                                    );
                                                }}
                                                size={4}
                                                maskedValue={vereador.funcao}
                                                indexError="vereadores"
                                                indexField={index}
                                            />
                                            <Icon
                                                marginBottom="10px"
                                                marginRight="15px"
                                                className="icon-trash"
                                                size="16px"
                                                color={colors.grey_placeholder}
                                                title="Remover campos"
                                                onClick={() => {
                                                    if (index !== 0) {
                                                        arrayHelpers.remove(
                                                            index
                                                        );
                                                    }
                                                }}
                                                hover={colors.white}
                                                kind={
                                                    index === 0
                                                        ? 'disabled-button'
                                                        : 'action-button'
                                                }
                                            />
                                        </ContainerVereadores>
                                    )
                                )}
                                <AddButton
                                    size={8}
                                    onClick={() => {
                                        if (
                                            formState.values.vereadores.length <
                                            totalVereadores
                                        ) {
                                            arrayHelpers.push({
                                                vereador: {},
                                                funcao: {},
                                            });
                                        } else {
                                            info(
                                                'Limite de vereadores atingido!'
                                            );
                                        }
                                    }}
                                >
                                    Adicionar campo
                                </AddButton>
                            </>
                        )}
                    />
                </Row>
                <Row padding="0 15px">
                    {Object.entries(comissao).length > 0 && (
                        <Button
                            type="reset"
                            kind="cancel"
                            margin="0 10px 0 0"
                            onClick={() => {
                                setComissao({});
                                formState.resetForm();
                            }}
                        >
                            Cancelar
                        </Button>
                    )}
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    function renderForm() {
        return (
            <PageContainer title="Comissão">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        nome: yup
                            .string()
                            .required('Nome é obrigatório!')
                            .max(255, '255 caracteres máximos'),
                        sigla: yup.string().max(20, '20 caracteres máximos'),
                        data_criacao: yup
                            .mixed()
                            .required('Data de Criação é obrigatória!')
                            .validDate('Data inválida'),
                        ativo: yup.object().validSelect('Campo obrigatório!'),
                        vereadores: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    funcao: yup
                                        .object()
                                        .validSelect('Função é obrigatória!'),
                                    vereador: yup
                                        .object()
                                        .validSelect('Vereador é obrigatório!'),
                                })
                            )
                            .required('Vereadores são obrigatórios')
                            .min(1, 'Selecione pelo menos um vereador'),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        );
    }

    return (
        <PageContainer padding scroll>
            {renderForm()}
        </PageContainer>
    );
}

export default Comissao;
