import React, { useRef, useState, useEffect } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router';

import { Field, Formik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import { Row } from '../../../app/global-styles';
import { loading, success, error } from '../../../components/alerts';
import Button from '../../../components/button';
import confirmAlert from '../../../components/confirm-alert';
import ControlledInput from '../../../components/form-components/controlled-input';
import Icon from '../../../components/icon';
import PageContainer from '../../../components/page-container';
import Popup from '../../../components/popup';
import Reordable, {
    listOrdered,
    listParse,
} from '../../../components/reordable';
import ReorderPopup from '../../../components/reorder-popup';
import Resizer from '../../../components/resizer';
import TableFileUploader from '../../../components/table-file-uploader';
import { colors } from '../../../configs/theme';
import Request, { getLimit } from '../../../utils/Request';
import { pathname2Query } from '../../../utils/RouterRes';
import { parser } from '../../../utils/Select';

function Materia() {
    const [materia, setMateria] = useState({});
    const [firstUploadFiles, setFirstUploadFiles] = useState([]);
    const [reorableList, setReorableList] = useState();
    const tableRef = useRef();
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    const url_params = pathname2Query(location.pathname, match.url);

    const initialValues =
        materia && Object.keys(materia).length > 0
            ? materia
            : {
                  tipo_materia: {},
                  situacao_materia: {},
                  origem: {},
                  ano: '',
                  data_apresentacao: '',
                  numero: '',
                  protocolo: '',
                  ementa: '',
              };

    async function getPessoa(id) {
        const request = new Request();

        const req_materia = request.setRequest('materias', 'listar', { id });

        const result = await request.execute();

        const { dados } = result[req_materia];

        if (dados && dados.id) {
            const materia_to_edit = {};
            materia_to_edit.numero = dados.numero;
            materia_to_edit.protocolo = dados.protocolo;
            materia_to_edit.ementa = dados.ementa;
            materia_to_edit.id = dados.id;

            if (dados.data_apresentacao && dados.data_apresentacao !== '') {
                materia_to_edit.data_apresentacao = moment(
                    dados.data_apresentacao,
                    'YYYY-MM-DD'
                ).toDate();
            } else {
                materia_to_edit.data_apresentacao = '';
            }

            if (dados.ano && dados.ano !== '') {
                materia_to_edit.ano = moment(dados.ano, 'YYYY').toDate();
            } else {
                materia_to_edit.ano = '';
            }

            if (dados.tipo_id && !isNaN(dados.tipo_id)) {
                materia_to_edit.tipo_materia = {
                    value: dados.tipo_id,
                    label: dados.materia_tipo_descricao,
                };
            } else {
                materia_to_edit.tipo_materia = {};
            }

            if (dados.situacao_id && !isNaN(dados.situacao_id)) {
                materia_to_edit.situacao_materia = {
                    value: dados.situacao_id,
                    label: dados.materia_situacao_descricao,
                };
            } else {
                materia_to_edit.situacao_materia = {};
            }

            if (dados.origem_id && !isNaN(dados.origem_id)) {
                materia_to_edit.origem = {
                    value: dados.origem_id,
                    label: dados.nome_materia,
                };
            } else {
                materia_to_edit.origem = {};
            }

            setMateria(materia_to_edit);
        } else {
            history.push('/painel/materias');
        }
    }

    useEffect(() => {
        if (url_params.id && !isNaN(url_params.id)) {
            getPessoa(url_params.id).then();
        } else {
            setMateria({});
            setFirstUploadFiles([]);
            tableRef.current.fireFetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_params.id]);

    async function getMateriaTipoSelect(search) {
        const request = new Request();

        const req_materia_tipos = request.setRequest(
            'materia_tipos',
            'listar',
            { search }
        );
        const result = await request.execute();

        const { dados } = result[req_materia_tipos] || [];

        return parser('descricao', 'id', dados);
    }

    async function getMateriaSituacaoSelect(search) {
        const request = new Request();

        const req_materia_situacoes = request.setRequest(
            'materia_situacoes',
            'listar',
            { search }
        );
        const result = await request.execute();

        const { dados } = result[req_materia_situacoes] || [];

        return parser('descricao', 'id', dados);
    }

    async function getMateriaSelect(search) {
        const request = new Request();

        const req_materia_situacoes = request.setRequest('materias', 'listar', {
            search,
        });
        const result = await request.execute();

        const { dados } = result[req_materia_situacoes] || [];

        return parser('nome_materia', 'id', dados);
    }

    async function setFileToMateria(file) {
        const request = new Request();

        const anexo = {
            midia_id: file.id,
            materia_id: url_params.id,
        };

        try {
            const req_file_materia = request.setRequest(
                'materia_anexos',
                'salvar',
                { anexo }
            );

            const result = await request.execute();

            if (result && result[req_file_materia]) {
                success(`Anexo "${file.titulo}" salvo com sucesso!`);
                tableRef.current.fireFetchData();
            } else {
                error('Falha ao realizar upload do anexo!');
            }
        } catch (e) {
            error('Sistema indispoinível.');
        }
    }

    async function handleReorder(ordered_list) {
        if (ordered_list) {
            const request = new Request();

            const req_reordenar = request.setRequest(
                'materia_anexos',
                'reordenar',
                { materia_id: url_params.id, lista: ordered_list }
            );

            const result = await request.execute();

            tableRef.current.fireFetchData();
            return result && result[req_reordenar];
        }
        return [];
    }

    async function handleSubmit(values, formState) {
        const materia_to_save = {};
        const loadToast = loading('Salvando matéria');

        materia_to_save.numero = values.numero;
        materia_to_save.protocolo = values.protocolo;
        materia_to_save.ementa = values.ementa;

        if (url_params.id) {
            materia_to_save.id = url_params.id;
        }

        if (values.tipo_materia.value) {
            materia_to_save.tipo_id = values.tipo_materia.value;
        }

        if (values.situacao_materia.value) {
            materia_to_save.situacao_id = values.situacao_materia.value;
        }

        if (values.origem.value) {
            materia_to_save.origem_id = values.origem.value;
        }

        if (values.ano) {
            materia_to_save.ano = moment(values.ano).format('YYYY');
        }

        if (values.data_apresentacao) {
            materia_to_save.data_apresentacao = moment(
                values.data_apresentacao
            ).format('YYYY-MM-DD');
        }

        try {
            const request = new Request();

            const req_materia = request.setRequest('materias', 'salvar', {
                materia: materia_to_save,
                midias: firstUploadFiles,
            });

            const result = await request.execute();

            if (result[req_materia] === true) {
                loadToast();
                success(`Matéria alterada com sucesso`);
                formState.setSubmitting(false);
            } else if (Number(result[req_materia]) > 0) {
                loadToast();
                success(`Matéria adicionada com sucesso`);
                history.push(
                    `/painel/materias/editor/id=${result[req_materia]}`
                );
                formState.setSubmitting(false);
            } else {
                loadToast();
                error('Não foi possível salvar matéria!');
                formState.setSubmitting(false);
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar matéria!');
            formState.setSubmitting(false);
        }
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="numero"
                        type="text"
                        required
                        label="Número"
                        placeholder="Número"
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        name="ano"
                        type="year-picker"
                        required
                        label="Ano"
                        placeholder="Ano"
                        size={4}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="tipo_materia"
                        isClearable
                        type_select="async"
                        type="select"
                        label="Tipo de matéria"
                        placeholder="Tipo de matéria"
                        size={4}
                        required
                        defaultOptions
                        cacheOptions
                        loadOptions={getMateriaTipoSelect}
                    />
                    <Field
                        component={ControlledInput}
                        name="protocolo"
                        type="text"
                        label="Protocolo"
                        placeholder="Protocolo"
                        size={4}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="situacao_materia"
                        isClearable
                        type_select="async"
                        type="select"
                        label="Situação da matéria"
                        placeholder="Situação da matéria"
                        size={4}
                        required
                        defaultOptions
                        cacheOptions
                        loadOptions={getMateriaSituacaoSelect}
                    />
                    <Field
                        component={ControlledInput}
                        name="data_apresentacao"
                        type="date-picker"
                        required
                        label="Data de Apresentação"
                        placeholder="Data de Apresentação"
                        size={4}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="origem"
                        isClearable
                        type_select="async"
                        type="select"
                        label="Matéria origem"
                        placeholder="Matéria origem"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={getMateriaSelect}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="ementa"
                        type="text"
                        as="textarea"
                        height="160px"
                        label="Ementa da matéria"
                        placeholder="Ementa da matéria"
                        size={8}
                    />
                </Row>
                <Row contentEnd padding="0 15px">
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    async function getAnexos({ page, limit: table_limit } = {}) {
        if (url_params.id) {
            const request = new Request();

            const limit = table_limit ? getLimit(page, table_limit) : null;

            const params_search = {
                materia_id: url_params.id,
            };

            const req_materia_anexos = request.setRequest(
                'materia_anexos',
                'listar',
                { limit, params_search }
            );
            const result = await request.execute();

            if (result && result[req_materia_anexos]) {
                return result[req_materia_anexos];
            }
            return {};
        }
        return {};
    }

    async function desativarAnexo({ original }) {
        if (original && original.midia_id) {
            const desativar = async () => {
                const request = new Request();

                const { midia_id, materia_id } = original;

                const req_desativar = request.setRequest(
                    'materia_anexos',
                    'desativar',
                    { midia_id, materia_id }
                );
                const result = await request.execute();

                if (result[req_desativar] === true) {
                    success('Anexo excluído com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir anexo!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir anexo?',
                subtitle: `Ao confirmar o anexo ${original.titulo} será excluído!`,
                onConfirm() {
                    desativar(original).then();
                },
            });
        } else {
            error('Anexo inválida!');
        }
    }

    const tableProps = {
        headers: [
            {
                name: 'Mídia',
                accessor: 'titulo',
            },
            {
                name: 'Data de publicação',
                accessor: 'data',
                Cell: (cellProps) => {
                    // console.log(props);
                    return (
                        <>
                            {cellProps.value &&
                                cellProps.value
                                    .split(' ')
                                    .map((date) =>
                                        date
                                            .split('-')
                                            .reverse()
                                            .join('/')
                                    )
                                    .join(' ')}
                        </>
                    );
                },
            },
        ],
        tableRef,
        data_function: getAnexos,
        options: (table_props) => (
            <Icon
                hidden
                size="16px"
                hover={colors.red_error_message}
                color={colors.black_table}
                className="icon-trash"
                onClick={() => desativarAnexo(table_props)}
            />
        ),
    };

    if (firstUploadFiles.length > 0) {
        tableProps.data = firstUploadFiles;
    }

    return (
        <Resizer left={50} right={50} minLeft={500} minRight={500}>
            <PageContainer>
                <TableFileUploader
                    tableProps={tableProps}
                    onFirstUpload={(uploaded_file) => {
                        if (!url_params.id) {
                            setFirstUploadFiles((olderFiles) => [
                                ...olderFiles,
                                uploaded_file,
                            ]);
                        } else {
                            setFileToMateria(uploaded_file);
                        }
                    }}
                    buttons={({ openFileDialog }) => {
                        return (
                            <Row>
                                <Button
                                    position="relative"
                                    margin="0 0 5px 0"
                                    icon="icon-upload"
                                    iconSize={16}
                                    onClick={openFileDialog}
                                >
                                    Anexo
                                </Button>
                                {url_params.id && (
                                    <ReorderPopup
                                        dataInfo={{
                                            value: 'midia_id',
                                            label: 'titulo',
                                        }}
                                        getData={getAnexos}
                                        handleReorder={handleReorder}
                                    />
                                )}
                            </Row>
                        );
                    }}
                />
            </PageContainer>
            <PageContainer title="Matéria">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        tipo_materia: yup
                            .mixed()
                            .validSelect('Tipo de matéria é obrigatório!'),
                        situacao_materia: yup
                            .mixed()
                            .validSelect('Situação da matéria é obrigatório!'),
                        ano: yup
                            .mixed()
                            .validDate('Ano inválido!')
                            .required('Ano é obrigatório!'),
                        data_apresentacao: yup
                            .mixed()
                            .validDate('Data inválida!'),
                        numero: yup.string().required('Número é obrigatório!'),
                        ementa: yup.string(),
                        protocolo: yup.string(),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        </Resizer>
    );
}

// <Table
//     headers={[
//         {
//             name: 'Titulo',
//             accessor: 'titulo',
//         },
//         {
//             name: 'Autor',
//             accessor: 'autor_nome',
//         },
//         {
//             name: 'Situação',
//             accessor: 'situacao',
//         },
//     ]}
//     clickHandler={(row, column, state, instance) => {
//         if (row) {
//             const {original} = row;
//             const {id} = original || 0;
//
//             if (id > 0 && instance.props.Checkbox) {
//                 instance.props.Checkbox.toggleSelected(id);
//             }
//         }
//
//     }}
//     noDataText="Nenhuma matéria associada à esse diário ainda"
//     tableRef={tableRef}
//     data_function={() => {
//     }}
// />

export default Materia;
