import React, { useContext } from 'react';
import { useLocation, useRouteMatch, Link } from 'react-router-dom';

import { AppContext } from '../../app/app-container';
import {
    getTabGroup,
    getGroupInfo,
    getPathPage,
} from '../../configs/tab-groups';
import { colors } from '../../configs/theme';
import Icon from '../icon';
import { Container, Group } from './styles';
import TabTab from './tabtab';

function HeaderNavigation(props) {
    const { hasPermissao, menu_painel } = useContext(AppContext);
    const location = useLocation();
    const routeMatch = useRouteMatch();
    const { painel } = props;

    const tabs = getTabGroup(location.pathname, routeMatch.url).filter(
        (tab) => tab.permissao && hasPermissao(tab.permissao)
    );

    const group = getGroupInfo(
        menu_painel,
        location.pathname,
        routeMatch.url,
        painel
    );

    const links = getPathPage(
        menu_painel,
        group,
        tabs,
        location.pathname,
        painel
    );

    return (
        <Container>
            <Group>
                <h2>{group.title}</h2>
                <nav>
                    {links.map((link, idx) => (
                        <React.Fragment key={idx}>
                            {idx > 0 && (
                                <Icon
                                    size="7px"
                                    className="icon-seta"
                                    color={colors.text_gray_three}
                                />
                            )}
                            <Link to={link.link}>{link.title}</Link>
                        </React.Fragment>
                    ))}
                </nav>
            </Group>
            <TabTab tabs={tabs} />
        </Container>
    );
}

export default HeaderNavigation;
