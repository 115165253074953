import CheckboxGroup from 'react-checkbox-group';

import styled, { css } from 'styled-components';

import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';

export const Container = styled('div')`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
    padding: 0 15px;
    width: ${(props) => props.width};

    ${(props) =>
        props.inTable &&
        css`
            padding: 0;
            margin: 0;
        `}
`;

export const GroupContainer = styled('div')`
    display: flex;
    flex-wrap: wrap;
    height: auto;
    width: 100%;
`;

export const StyledGroup = styled(CheckboxGroup)`
    display: flex;
    height: auto;
    width: auto;
`;

const buttonStyle = css`
    background: ${colors.grey_lines_table};
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid ${colors.grey_pagination};
    color: ${colors.grey_placeholder};
    font-size: ${parse(14)};
    padding: 6.5px 10px;
    border-radius: 4px;
    line-height: 20px;

    ${(props) =>
        !props.checked &&
        css`
            &:hover {
                border-color: ${colors.color_primary_hover_selected};
            }
        `}

    p {
        font-weight: bold;
    }

    ${(props) =>
        props.checked &&
        css`
            background-color: ${colors.white};
            border-color: ${colors.color_primary};

            color: ${colors.color_primary};
        `}

    span,
    input {
        display: none;
    }
`;

export const InputLabel = styled('label')`
    align-items: center;
    display: flex;
    font-weight: bold;
    margin: ${(props) => props.margin};
    color: ${colors.black_table};

    input {
        margin-right: 5px;
    }

    ${(props) => props.buttonstyle && buttonStyle}
    ${(props) =>
        props.inTable &&
        css`
            margin-bottom: 0;
        `}
`;
