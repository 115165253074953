export const menu_painel = [
    {
        icon: 'icon-home',
        link: '/painel',
        title: 'Início',
    },
    {
        icon: 'icon-pessoas',
        link: '/painel/comissoes',
        title: 'Comissões',
        // permissao: ['R_DIARIOS', 'M_DIARIOS'],
    },
    {
        icon: 'icon-contratos',
        link: '/painel/materias',
        title: 'Matérias',
        // permissao: ['R_DIARIOS', 'M_DIARIOS'],
    },
    {
        icon: 'icon-microfone',
        link: '/painel/sessoes',
        title: 'Sessões',
        // permissao: ['R_DIARIOS', 'M_DIARIOS'],
    },
    {
        icon: 'icon-legislacao',
        link: '/painel/legislaturas',
        title: 'Legislaturas',
    },
    {
        icon: 'icon-pessoal',
        link: '/painel/vereadores',
        title: 'Vereadores',
    },
    {
        icon: 'icon-pessoal',
        link: '/painel/usuarios',
        title: 'Usuários',
        hide: true,
    },
];
