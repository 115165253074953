import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import ErrorMessage from '../error-message';
import { Label, LabelMessage, Required } from '../styles';
import { Container, GroupContainer, StyledGroup, InputLabel } from './styles';

function CheckBoxGroup({
    name,
    id,
    label,
    error_message,
    required,
    buttonstyle,
    inTable,
    onChange,
    value,
    margin,
    loadOptions,
    ...props
}) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        async function setOptionsMount() {
            if (loadOptions) {
                const request_options = await loadOptions();
                setOptions(request_options);
            } else {
                setOptions(props.options);
            }
        }

        setOptionsMount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container inTable={inTable} width={props.width}>
            {label && (
                <Label>
                    <LabelMessage>
                        {label}
                        {required && <Required>*</Required>}
                    </LabelMessage>
                </Label>
            )}
            <GroupContainer>
                <StyledGroup name={name} value={value} onChange={onChange}>
                    {(CheckBox) => (
                        <>
                            {options.map((option) => (
                                <InputLabel
                                    key={option.value}
                                    inTable={inTable}
                                    buttonstyle={buttonstyle}
                                    checked={
                                        value && value.includes(option.value)
                                    }
                                    show_error={!!error_message}
                                    margin={margin}
                                >
                                    <CheckBox value={option.value} />
                                    {option.label}
                                </InputLabel>
                            ))}
                        </>
                    )}
                </StyledGroup>
            </GroupContainer>
            {error_message && <ErrorMessage error_message={error_message} />}
        </Container>
    );
}

export default CheckBoxGroup;

CheckBoxGroup.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    error_message: PropTypes.string,
    value: PropTypes.array,
    required: PropTypes.bool,
    buttonstyle: PropTypes.bool,
    inTable: PropTypes.bool,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    loadOptions: PropTypes.func,
    margin: PropTypes.string,
    width: PropTypes.string,
};

CheckBoxGroup.defaultProps = {
    name: null,
    id: null,
    loadOptions: null,
    width: '100%',
    value: [],
    label: null,
    error_message: null,
    buttonstyle: false,
    inTable: false,
    required: false,
    margin: '0 15px 10px 0',
    onChange: null,
};
