import styled, { css } from 'styled-components';

import { colors } from '../../../configs/theme';

const getContainerStyle = (props) => {
    if (props.isDragAccept) {
        return css`
            border-color: ${colors.primary_green};
        `;
    }

    if (props.isDragReject) {
        return css`
            border-color: ${colors.red};
        `;
    }
};

export const Container = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
`;

export const ContainerIcon = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    border: 2px dashed #cccccc;
    border-radius: 50%;
    ${(props) => getContainerStyle(props)}
`;

export const Descricao = styled('p')`
    font-size: 14px;
    color: #999;
    margin-top: 10px;
`;
