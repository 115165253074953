import styled, { css } from 'styled-components';

export const Container = styled('ul')`
    width: 100%;
    height: auto;
    margin-bottom: 5px;
    ${(props) =>
        props.zIndex &&
        css`
            z-index: ${props.zIndex};
        `}
`;
