/*
 * Configurações imutaveis do sistema
 */

const tag_base = document.getElementsByTagName('base');

/**
 * A URL para a API
 *
 * @type {string}
 */
export const URL_API = `${window.app.api}`;

/**
 * Informa se a pagina possui uma tag base
 *
 * @return {boolean}
 */
export const HAS_BASE = tag_base.length > 0;

/**
 * Retorna a url ate o diretório onde está localizando o index
 *
 * @return {string}
 */
export const BASE = HAS_BASE
    ? tag_base[0].href.replace(/[/]+$/, '')
    : `${window.location.protocol}//${window.location.host}`;

/**
 * Tawkto token
 */
export const TAWKTO_TOKEN = '58d2d9aef7bbaa72709c1eaf/1e0ocp0uv';
