import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';

import { Field, Formik } from 'formik';
import moment from 'moment';

import { AppContext } from '../../../app/app-container';
import { Row } from '../../../app/global-styles';
import { error, success } from '../../../components/alerts';
import confirmAlert from '../../../components/confirm-alert';
import ControlledInput from '../../../components/form-components/controlled-input';
import Icon from '../../../components/icon';
import PageContainer from '../../../components/page-container';
import Table from '../../../components/table';
import { colors } from '../../../configs/theme';
import Request, { getLimit } from '../../../utils/Request';
import 'moment/locale/pt-br';
import {parser} from "../../../utils/Select";

moment.locale('pt-br');

function Legislaturas() {
    const { hasPermissao } = useContext(AppContext);
    const history = useHistory();
    const tableRef = useRef();
    const [searchParams, setSearchParams] = useState({});

    const initialFilters = {
        search: '',
        vereador: {},
    };

    useEffect(() => {
        tableRef.current.fireFetchData();
    }, [searchParams]);

    async function getLegislaturas({ page, limit: table_limit }) {
        const request = new Request();

        const limit = getLimit(page, table_limit);

        const req_legislaturas = request.setRequest('legislaturas', 'listar', {
            limit,
            ...searchParams,
        });
        const result = await request.execute();

        if (result && result[req_legislaturas]) {
            return result[req_legislaturas];
        }
        return {};
    }

    async function getVereadoresSelect(search, ids) {
        const request = new Request();
        const params_search = {
            ids,
        };

        const req_vereadores = request.setRequest('vereadores', 'listar', {
            search,
            params_search,
        });

        const result = await request.execute();

        const { dados } = result[req_vereadores] || [];

        return parser('nome', 'id', dados);
    }

    async function desativarLegislatura({ original }) {
        if (original && original.id) {
            const desativar = async () => {
                const request = new Request();

                const { id } = original;

                const req_desativar = request.setRequest(
                    'legislaturas',
                    'desativar',
                    { id }
                );
                const result = await request.execute();

                if (result[req_desativar] === true) {
                    success('Legislatura excluída com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir legislatura!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir legislatura?',
                subtitle: `Ao confirmar a legislatura será removida!`,
                onConfirm() {
                    desativar(original).then();
                },
            });
        } else {
            error('Legislatura inválida!');
        }
    }

    function makeFormFiltros(formikState) {
        return (
            <Row as="form" wrap="wrap">
                <Field
                    name="search"
                    type="text"
                    placeholder="Filtrar"
                    size={1}
                    minWidth={210}
                    onChange={(e) => {
                        e.preventDefault();
                        const { value, name } = e.target;
                        setSearchParams({ ...searchParams, [name]: value });
                    }}
                    icon="icon-lupa"
                    kind="filtros"
                    show_filtros={false}
                    component={ControlledInput}
                />
                <Field
                    component={ControlledInput}
                    name="vereador"
                    margin="0 0 0 15px"
                    padding="0"
                    isClearable
                    type_select="async"
                    minWidth={210}
                    type="select"
                    placeholder="Vereador"
                    size={1}
                    onChange={({ value }) => {
                        setSearchParams({ ...searchParams, vereador: value });
                    }}
                    kind="filtros"
                    defaultOptions
                    cacheOptions
                    loadOptions={getVereadoresSelect}
                />
                {Object.keys(searchParams).some(
                    (key) => searchParams[key] && searchParams[key] !== ''
                ) && (
                    <Icon
                        className="icon-limpar"
                        size="26px"
                        color={colors.grey_placeholder}
                        title="Limpar filtros"
                        onClick={() => {
                            setSearchParams({});
                            formikState.resetForm({ ...initialFilters });
                        }}
                        hover={colors.white}
                        marginLeft="15px"
                        kind="action-button"
                    />
                )}
            </Row>
        );
    }

    return (
        <PageContainer padding scroll>
            <Row margin="0 0 21px 0">
                <Formik initialValues={{ ...initialFilters }}>
                    {makeFormFiltros}
                </Formik>
            </Row>
            <Table
                headers={[
                    {
                        name: 'Ano Inicial',
                        accessor: 'ano_inicio',
                        maxWidth: '50%',
                    },
                    {
                        name: 'Ano Final',
                        accessor: 'ano_fim',
                        maxWidth: '50%',
                    },
                ]}
                options={(table_props) => (
                    <Icon
                        hidden
                        size="16px"
                        hover={colors.red_error_message}
                        color={colors.black_table}
                        className="icon-trash"
                        onClick={() => desativarLegislatura(table_props)}
                    />
                )}
                tableRef={tableRef}
                data_function={getLegislaturas}
                clickHandler={(row, column) => {
                    if (
                        column.id !== 'options' &&
                        row &&
                        row.original &&
                        row.original.id
                    ) {
                        history.push(
                            `/painel/legislaturas/editor/id=${row.original.id}`
                        );
                    }
                }}
            />
        </PageContainer>
    );
}

export default Legislaturas;
